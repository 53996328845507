import axios from "axios";
import qs from 'qs';

const state = {
    soldOrders: null,
    buyOrders: null,
    sellOrder: null,
    buyOrder: null,
    shippingMethods: null,
    sellOrderParams:{
        order_number:'',
        ndc:'',
        product_name:'',
        seller:'',
        created_at_from:'',
        created_at_to:'',
        page:1,
        per_page: 100,
        order: 'asc'
    },
    buyOrderParams:{
        order_number:'',
        ndc:'',
        product_name:'',
        page:1,
        per_page: 100,
        order: 'asc'
    },
    statusesList:[
        {
            id:'new',
            title:'New',
            color: 'black'
        },
        {
            id:'payment_in_process',
            title:'Payment in process',
            color: 'green',
        },
        {
            id:'failed_payment',
            title:'Failed payment',
            color: 'red',
        },
        {
            id:'paid',
            title:'Paid',
            color: 'green',
        },
        {
            id:'in_progress',
            title:'In progress',
            color: 'orange',
        },
        {
            id:'delivery',
            title:'Delivery',
            color: 'orange',
        },
        {
            id:'delivered',
            title:'Delivered',
            color: 'orange',
        },
        {
            id:'completed',
            title:'Completed',
            color: 'green',
        },
        {
            id:'canceled',
            title:'Canceled',
            color: 'red',
        },
    ],
    cancelOrderItem: null,
}

const getters = {
    soldOrders: state => state.soldOrders,
    sellOrder: state => state.sellOrder,
    buyOrders: state => state.buyOrders,
    buyOrder: state => state.buyOrder,
    shippingMethods: state => state.shippingMethods,
    statusesList: state => state.statusesList,
    cancelOrderItem: state => state.cancelOrderItem,
    sellOrderParams: state => state.sellOrderParams,
    buyOrderParams: state => state.buyOrderParams,
}

const actions = {
    getSoldOrders: async ({commit}, payload) => {
        console.log('payload - ', payload)
        try {
            const result = await axios.get('/orders/sold', {
                params: payload,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            });
            commit('changeSoldOrders', result.data)
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getSoldOrderDetail: async ({commit}, id) => {
        try {
            const result = await axios.get(`/orders/sold/${id}`);
            commit('changeSoldOrder', result.data)
        }catch (e){
            console.log(e)
        }
    },
    cancelSoldOrder: async (ctx, payload) => {
        try{
            const result = await axios.post(`/orders/sold/${payload.id}/cancel`,{
                message: payload.message
            })
            return {
                result: true,
                data: result
            }
        }catch (e){
            return{
                result: false,
                data: e.response.data
            }
        }
    },
    confirmSoldOrder: async (ctx, id) => {
        try{
            const result = await axios.post(`/orders/sold/${id}/confirm`)
            return {
                result: true,
                data: result
            }
        }catch (e){
            return{
                result: false,
                data: e.response.data
            }
        }
    },
    getBuyOrders: async ({commit}, payload) => {
        try {
            const result = await axios.get('/orders/purchased', {
                params: payload,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            });
            commit('changeBuyOrders', result.data)
        }catch (e){
            console.log(e)
        }
    },
    getBuyOrderDetail: async ({commit}, id) => {
        try {
            const result = await axios.get(`/orders/purchased/${id}`)
            commit('changeBuyOrder', result.data)
        }catch (e){
            console.log(e)
        }
    },
    cancelRequestBuyOrder: async (ctx, id) => {
      try {
          const result = await axios.post(`/orders/purchased/${id}/cancel-request`)
          return {
              status: true,
              data: result.data
          }
      }catch (e){
          return{
              status: false,
              data: e.response.data
          }
      }
    },
    buyOrdersInCard: async (ctx, method) => {
        try{
            const result = await axios.post(`/orders/buy?shipping_method=${method}`)
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getShippingMethods: async ({commit}) => {
        try{
            const result = await  axios.get('/shipping/price-list')
            commit('changeShippingMethods', result.data)
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    }
}

const mutations = {
    changeSoldOrders(state, result){
        state.soldOrders = result
        state.soldOrders.data.map(item=>{
            item['isOpen'] = false;
        })
    },
    changeSoldOrder(state, order){
        state.sellOrder = order.data

    },
    changeBuyOrders(state, data){
        state.buyOrders = data
        state.buyOrders.data.map(item=>{
            item['isOpen'] = false;
        })
    },
    changeBuyOrder(state, data){
        state.buyOrder = data
    },
    changeShippingMethods(state, data){
        state.shippingMethods = data
    },
    changeCancelOrderItem(state, item){
        state.cancelOrderItem = item;
    },
    changeSellOrderParams(state, data){
        Object.keys(data).map(item=>{
            state.sellOrderParams[item] = data[item]
        })
    },
    changeBuyOrderParams(state, data){
        Object.keys(data).map(item=>{
            state.buyOrderParams[item] = data[item]
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
