<template>
  <div>
    <div v-if="!preLoading">
      <Header :mainBanner="textBlock['main_banner']"/>
      <Info :infoBlock="textBlock['like_a_benefits']"/>
      <About/>
      <Why/>
      <Faq/>
      <Testimonials/>
      <ContactUs/>
      <Footer :footer="textBlock['footer']"/>
      <transition name="bar-show">
        <Registration v-if="toggleRegistration"/>
        <Login v-if="toggleLogin"/>
        <ForgotPassword v-if="toggleForgotPassword"/>
        <ChangePassword v-if="toggleChangePassword"/>
      </transition>
      <scroll-up :scroll-duration="1000" :scroll-y="250"></scroll-up>
    </div>
    <div v-else><Loader/></div>
  </div>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

export default {
  components:{
    Header: () => import('../components/LandingPage/header'),
    Footer: () => import('../components/LandingPage/footer'),
    Info: () => import('../components/LandingPage/info'),
    About: () => import('../components/LandingPage/about'),
    Why: () => import('../components/LandingPage/why'),
    Faq: () => import('../components/LandingPage/faq'),
    Testimonials: () => import('../components/LandingPage/testimonials'),
    ContactUs: () => import('../components/LandingPage/contactUs'),
    Registration: () => import('../components/auth/registration'),
    Login: () => import('../components/auth/login'),
    ForgotPassword: () => import('../components/auth/forgotPassword'),
    ChangePassword: () => import('../components/auth/changePassword'),
    ScrollUp: () => import('vue-scroll-up'),
    Loader: () => import('../components/loader/index')
  },
  computed:{
    ...mapGetters({
      toggleRegistration: 'general/toggleRegistration',
      toggleLogin: 'general/toggleLogin',
      toggleForgotPassword: 'general/toggleForgotPassword',
      toggleChangePassword: 'general/toggleChangePassword',
      textBlock: 'landing/textBlock',
      preLoading: 'landing/preLoading'
    })
  },
  created() {
    this.getAboutUsBlocks();
    this.getFaqList();
    this.getTestimonials();
  },
  methods:{
    ...mapActions({
      getAboutUsBlocks: 'landing/getAboutUsBlocks',
      getFaqList: 'landing/getFaqList',
      getTestimonials: 'landing/getTestimonials',
    })
  }
}
</script>
