<template>
  <div>
    <Header/>
    <menuList class="mb-2"/>
    <Footer/>
  </div>
</template>
<script>
export default {
  components:{
    Header: () => import('../components/common/header'),
    Footer: () => import('../components/common/footer'),
    menuList: () => import('../components/common/menu-list'),
  },
  created() {
    this.$router.push('/products/my-posting')
  }
}
</script>
<style lang="scss">
  @import "src/assets/styles/styles";
</style>
