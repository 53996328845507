<template>
  <div>
    <Header class="mb-2"/>
    <div class="dashboard">
    <div class="container">
      <div class="row">
        <div class="info-block">
          {{$t('dashboard.title_info')}}
        </div>
          <div class="dashboard__title"><span>{{$t('dashboard.title')}}</span></div>
          <div class="dashboard__block">
            <div class="row">
              <label class="input-block col-md-5">
                <input type="text"  v-model="search.product_name"><span/>
                <span class="input-block__name" :class="search.product_name.length ?'active' : null" >Type Your Drug Name (Like: Pantoprazole) OR NDC</span>
              </label>
              <label class="select-block col-md-4" v-if="wholesalerList">
                <v-select
                    v-model="search_type_id"
                    :options="wholesalerList"
                    :reduce="(i) => i.id"
                    label="title"
                    placeholder="Select Wholesaler/Pharmacy "
                />
                <span class="bottom"/>
              </label>
              <div class="col-md-3 d-flex justify-center">
                <button class="btn grey" @click="submitSearch">{{$t('global.submit')}}</button>
              </div>
              <div class="dashboard__text col-md-12 mt-1">
                {{$t('dashboard.submit_info')}}
              </div>
              <label class="checkbox-block col-md-8 mt-3">
              </label>
              <div class="dashboard__block-more col-md-4 mt-3" @click="toggleBlockMore = !toggleBlockMore">
                <span v-if="toggleBlockMore">Hide options</span>
                <span v-else>Open more options</span>
                <div class="arrow" :class="toggleBlockMore ? 'open' : null">
                  <inline-svg :src="require('@/assets/images/svg/order-arrow.svg')"></inline-svg>
                </div>
              </div>
              <div class="dashboard__more col-md-12 mt-3 row" :class="toggleBlockMore ? 'open' : null">
                <label class="input-block price col-md-3">
                  <p class="price-ic">$</p>
                  <input type="text"  v-model="search.price_min"><span/>
                  <span class="input-block__name active">Price from</span>
                </label>
                <label class="input-block price col-md-3">
                  <p class="price-ic">$</p>
                  <input type="text"  v-model="search.price_max"><span/>
                  <span class="input-block__name active" >Price to</span>
                </label>
                <label class="input-block date col-md-3">
                  <input type="month" id="start" name="start"
                         v-model="search.exp_date_min"
                         min="2018-03" value="2018-05"><span/>
                  <span class="input-block__name active">Expiration date start</span>
                </label>
                <label class="input-block date col-md-3">
                  <input type="month" id="end" name="start"
                         v-model="search.exp_date_max"
                         min="2018-03" value="2018-05"><span/>
                  <span class="input-block__name active">Expiration date end</span>
                </label>
                <label class="input-block col-md-3">
                  <input type="text"  v-model="search.manufacturer_name"><span/>
                  <span class="input-block__name" :class="search.manufacturer_name.length ?'active' : null" >Manufacturer*</span>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard-search" v-if="productList">
      <div class="container">
        <div class="row">
          <div class="dashboard-search__title col-sm-12 col-md-8">
            <span>Search result</span>
          </div>
          <div class="search-sort col-sm-12 col-md-4">
            <p :class="search.order === 'desc' ? 'active' : null" @click="changeSortProduct('desc')">hight price</p>
            <p :class="search.order === 'asc' ? 'active' : null" @click="changeSortProduct('asc')">Low price</p>
          </div>
        </div>
        <div class="row">
          <div class="dashboard-search__list" v-if="productList.data.length">
            <ProductItem v-for="(item, index) in productList.data" :key="index" :item="item"/>
          </div>
          <div class="dashboard-search__empty" v-else>
            <p>Nothing found</p>
          </div>
        </div>
      </div>
    </div>
    <Pagination v-if="searchList.length"/>
    <Footer/>
    <scroll-up :scroll-duration="1000" :scroll-y="250"></scroll-up>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components:{
    Header: () => import('../components/common/header'),
    Footer: () => import('../components/common/footer'),
    Pagination: () => import('../components/common/pagination'),
    ProductItem: () => import('../components/dashboard/item'),
    ScrollUp: () => import('vue-scroll-up'),
  },
  data(){
    return{
      checkPremiumSearch:0,
      search:{
        product_name:'',
        price_min:'',
        price_max:'',
        exp_date_min:'',
        exp_date_max:'',
        manufacturer_name:'',
        order:'desc',
        sort:'price',
      },
      search_type_id:null,
      wholesalerList:[
        {
          id:'0',
          title:'WHOLESALER',
        },
        {
          id:'1',
          title:'PHARMACY',
        },
      ],
      toggleBlockMore: false,
    }
  },
  computed:{
    ...mapGetters({
      searchList:'dashboard/searchList',
      productList: 'product/productList'
    }),
  },
  created() {
    this.item
  },
  methods:{
    submitSearch(){
      let payload = {};
      this.changeToggleLoading(true);
      Object.keys(this.search).map(item=>{
        if(this.search[item].length){
          payload[item] = this.search[item];
        }
      })
      this.getProducts(payload).then(()=>{
        this.changeToggleLoading(false)
      });
    },
    changeSortProduct(type){
      this.search.order = type;
      this.submitSearch();
    },
    ...mapMutations({
      changeToggleLoading: 'general/changeToggleLoading'
    }),
    ...mapActions({
      getProducts: 'product/getProducts',
    }),
  },
}
</script>
<style lang="scss">
  @import "src/assets/styles/styles";
  .dashboard{
    &__title{
      margin-bottom: 35px;
      font-size: 24px;
      font-family: 'Mont', Arial;
      font-weight: 900;
      text-transform: uppercase;
      span{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          height: 20px;
          width: 20px;
          background-color: $yellow;
          border-radius: 50%;
          top: -20px;
          right: -150px;
        }
      }
    }
    &__block{
      background-color: $white-grey;
      border-radius: 27px;
      padding: 30px 65px;
      margin-bottom: 45px;
      .input-block span.input-block__name{
        font-size: 14px;
      }
    }
    &__text{
      font-family: 'Inter';
      font-weight: 400;
      font-size: 14px;
      color: $grey;
      opacity: 0.4;
    }

    .checkbox-block{
      height: auto;
    }
    &__block-more{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: $blue;
      font-size: 16px;
      font-family: 'Mont', Arial;
      position: relative;
      cursor: pointer;
      .arrow{
        margin-right: 35px;
        opacity: 0.15;
        transition: 0.3s all;
        margin-left: 20px;
        &.open{
          transform: rotate(90deg);
        }
      }
    }
    &__more{
      border-top: 1px solid transparent;
      overflow: hidden;
      max-height: 0;
      transition: 0.3s all;
      &.open{
        border-top: 1px solid $grey02;
        padding: 15px 0;
        max-height: 1000px;
      }
    }
  }
  .dashboard-search{
    position: relative;
    padding: 45px 0;
    &:after{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 250px;
      width: 100vw;
      background-color: $grey;
      z-index: -1;
    }
    &__title{
      margin-bottom: 35px;
      font-size: 24px;
      font-family: 'Mont', Arial;
      font-weight: 900;
      text-transform: uppercase;
      color: white;
      span{
        position: relative;
        &:after{
          content: '';
          position: absolute;
          height: 20px;
          width: 20px;
          background-color: $yellow;
          border-radius: 50%;
          top: -20px;
          right: -50px;
        }
      }
    }
    &__list{
      display: flex;
      flex-direction: column;
    }
    &__item{
      box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
      border-radius: 27px;
      background-color: $white;
      padding: 20px 30px;
      margin-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media screen and (max-width: 1220px) {
        flex-direction: column;
      }
    }
    &__item-center{
      display: flex;
      flex-direction: column;
    }
    &__item-info{
      display: flex;
      align-items: flex-end;
    }
    &__item-wish{
      cursor: pointer;
      margin-bottom: 15px;
      &:hover{
        opacity: 0.5;
      }
    }
    &__item-title{
      font-family: 'Inter', Arial;
      font-weight: 900;
      color: $grey;
      font-size: 22px;
      line-height: 1.4;
      margin-bottom: 30px;
    }
    &__item-span{
      width: 100%;
      padding: 13px 20px;
      background-color: $white-grey;
      border-radius: 13px;
      font-size: 16px;
      font-family: 'Inter', Arial;
      font-weight: 500;
    }
    &__item-name{
      width: 40%;
      font-family: 'Inter', Arial;
      font-weight: 400;
      font-size: 12px;
      text-transform: uppercase;
      color: $grey;
      opacity: 0.5;
      margin-bottom: 5px;
    }
    &__item-value{
      width: 60%;
      font-family: 'Inter', Arial;
      font-weight: 400;
      font-size: 16px;
      margin-bottom: 5px;
    }
    &__item-top{
      margin-bottom: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &__item-count{
      height: 48px;
      width: 100px;
      background-color: $white-grey;
      border: 1px solid rgba(223, 223, 223, 0.5);
      box-sizing: border-box;
      border-radius: 13px;
      position: relative;
      input{
        width: 100%;
        height: 100%;
        background-color: transparent;
        font-family: 'Inter', Arial;
        font-style: normal;
        font-weight: 300;
        font-size: 26px;
        line-height: 21px;
        opacity: 0.5;
        padding: 0 30px;
      }
    }
    &__item-price{
      font-size: 26px;
      font-family: 'Inter', Arial;
      font-weight: 900;
    }
    &__item-left{
      align-self: flex-start;
    }
    &__item-match{
      position: absolute;
      right: 10px;
      top: 7px;
      display: flex;
      flex-direction: column;
      cursor: pointer;
    }
    &__item-plus,
    &__item-minus{
      height: 12px;
      opacity: 0.6;
      &:hover{
        opacity: 1;
      }
    }
    &__empty{
      p{
        text-align: center;
        padding: 10px;
        margin: 50px 5px;
        border-radius: 15px;
        background-color: $white;
        box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
      }
    }
  }
</style>
