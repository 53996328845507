<template>
  <div class="stripe-return">
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";

export default {
  data(){
    return{
      token:'',
    }
  },
  created() {
    this.token = this.$route.params.id;
    console.log('token', this.token)
    this.sendTokenForPayoutAccount();
  },
  methods:{
    sendTokenForPayoutAccount(){
      this.changeToggleLoading(true);
      this.completePayoutAccount(this.token).then(res=>{
        if(res.result){
          this.$toasted.error('Payment account success added',{
            duration: 3000
          })
          this.$router.push({name: 'Profile'})
        }else{
          this.$toasted.error(res.data.message,{
            duration: 3000
          })
          setTimeout(()=>{
            this.$router.push({name: 'Profile'})
          },1000)
        }
        this.changeToggleLoading(false)
      })
    },
    ...mapActions({
      completePayoutAccount: 'profile/completePayoutAccount'
    }),
    ...mapMutations({
      changeToggleLoading: 'general/changeToggleLoading'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/styles";
.reset-password{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__block{
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 0 0 5px 1px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title{
    font-family: 'Mont';
    font-weight: 300;
    font-size: 56px;
  }
  &__link{
    margin-top: 10px;
    color: $blue;
  }
}
</style>
