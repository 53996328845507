import axios from "axios";
import qs from 'qs';

const state = {
    productList: null,
    cartProducts: null,
}

const getters = {
    productList: state => state.productList,
    cartProducts: state => state.cartProducts,
}

const actions = {
    getProducts: async ({commit}, payload) => {
        try {
            const result = await axios.get('/products?page=1&per_page=100', {
                params: payload,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            });
            commit('changeProductList', result.data)
        }catch (e){
            console.log(e)
        }
    },
    addToCart: async ({commit}, payload) => {
        try {
            const result = await axios.post(`/cart-products/${payload.id}`, {
                quantity: payload.quantity,
            })
            commit('changeAddToCartResult', result.data)
            return{
                status: true,
                data: result.data
            }
        }
        catch (e){
            console.log('e - ', e);
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getCartProducts: async ({commit}, payload) => {
        try {
            const result = await axios.get(`/cart-products`, {
                params: payload,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            })
            commit('changeCartProduct', result.data)
        }
        catch (e){
            console.log('e - ', e);
        }
    },
    removeCartItem: async (ctx, payload) => {
        try {
            const result = await axios.delete(`/cart-products/${payload.id}`)
            return{
                status: true,
                data: result.data
            }
        }
        catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
}

const mutations = {
    changeProductList(state, data){
        state.productList = data;
        state.productList.data.map(item => {
            item.count = 1;
        })
    },
    changeCartProduct(state, data){
        state.cartProducts = data;
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
