import axios from "axios";
import qs from 'qs';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('userToken'),
    'Accept':'application/json',
}

const state = {
    profile: null,
    userAnswers: null,
    profileProductList: null,
    profileProduct: null,
    payoutAccountUrl: null,
    paymentMethodSecret: null,
}

const getters = {
    profile: state => state.profile,
    userAnswers: state => state.userAnswers,
    profileProductList: state => state.profileProductList,
    payoutAccountUrl: state => state.payoutAccountUrl,
    profileProduct: state => state.profileProduct,
    paymentMethodSecret: state => state.paymentMethodSecret,
}

const actions = {
    getProfile: async({commit}) => {
        if(localStorage.getItem('userToken')){
            axios.defaults.headers = {
                'Authorization':'Bearer ' + localStorage.getItem('userToken')
            }
            try {
                const result = await axios.get('/users/me')
                commit('changeProfile', result.data.data)
                return{
                    status:true,
                }
            }catch (e){
                return{
                    status:false,
                    data: e.response.data
                }
            }
        }else{
            return {
                status: false,
                data: 'Not have token'
            }
        }
    },
    updateProfile: async({commit}, status) => {
            try {
                const result = await axios.patch('/users/me',{
                    ...status
                })
                commit('changeProfile', result.data.data)
                return{
                    status:true,
                }
            }catch (e){
                return{
                    status:false,
                    data: e.response.data
                }
            }
    },
    getUserAnswers: async({commit}) => {
        try {
            const result = await axios.get('/users/answers?page=1&per_page=100')
            commit('changeUserAnswers', result.data.data)
            return{
                status:true,
            }
        }catch (e){
            return{
                status:false,
                data: e.response.data
            }
        }
    },
    sendNewPassword: async(ctx, payload) => {
        try {
            await axios.post('/users/change-password', payload)
            return{
                status:true,
            }
        }catch (e){
            return{
                status:false,
                data: e.response.data
            }
        }
    },
    getProfileProductList: async({commit}, payload) => {
        try{
            const result = await axios.get(`/user-products`, {
                params: payload,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            });
            commit('changeProfileProductList', result.data);
        }catch (e){
            console.log('e - ', e)
        }
    },

    addNewProfileProduct: async(ctx, payload) => {
        try{
            const result = await axios.post('/user-products', payload)
            return {
                status: true,
                data: result
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },
    changeProfileProduct: async(ctx, payload) => {
        try{
            const result = await axios.patch(`/user-products/${payload.id}`, payload.data)
            return {
                status: true,
                data: result
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },
    removeProfileProduct: async(ctx, payload) => {
        try{
            const result = await axios.delete(`/user-products/${payload.id}`)
            return {
                status: true,
                data: result
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },
    connectPayoutAccount: async ({commit}) => {
        try {
            const result = await axios.post('/payment/connect-account')
            commit('changePayoutAccountUrl', result.data)

        }catch (e){
            console.log('e - ', e);
        }
    },
    completePayoutAccount: async (ctx, code) => {
        try {
            const result = await axios.post(`/payment/connect-account-return/${code}`)
            return{
                result: true,
                data: result.data
            }
        }catch (e){
            return{
                result: false,
                data: e.response.data
            }
        }
    },
    connectPaymentMethod: async ({commit}) => {
        try {
            const result = await axios.post('/payment/connect-payment-method')
            commit('getPaymentMethodSecret', result.data)
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    }
}

const mutations = {
    changeProfile(state, result){
        state.profile = result;
        localStorage.setItem('profile', JSON.stringify(state.profile));
    },
    changeUserAnswers(state, result){
        state.userAnswers = result;
    },
    changeProfileProductList(state, result){
        state.profileProductList = result;
    },
    editProfileProduct(state, item){
        state.profileProduct = item;
    },
    changePayoutAccountUrl(state, url){
        state.payoutAccountUrl = url;
    },
    getPaymentMethodSecret(state, key){
        state.paymentMethodSecret = key;
    }
}

export default {
    getters,
    state,
    actions,
    mutations,
    namespaced: true
}
