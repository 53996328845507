<template>
  <div class="wholesalers">
    <Header/>
    <div class="container mt-2 mb-2">
      <div class="row">
        <div class="title col-sm-12">
          <div class="row">
            <span>Manage Wholesalers</span>
          </div>
        </div>
      </div>
      <div class="wholesalers__list mt-2 row">
        <div class="col-md-4">
          <div class="wholesalers__item">
            <div class="wholesalers__item-photo">
              <img :src="require('@/assets/images/profile-img.png')" alt="wholesalers-photo">
            </div>
            <div class="wholesalers__item-info">
              <div class="wholesalers__item-title">
                Northeast Medical Exchange-ADR
              </div>
              <div class="wholesalers__item-inventory">
                View Inventory (45)

              </div>
              <div class="wholesalers__item-btn">
                Apply
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="wholesalers__item">
            <div class="wholesalers__item-photo">
              <img :src="require('@/assets/images/profile-img.png')" alt="wholesalers-photo">
            </div>
            <div class="wholesalers__item-info">
              <div class="wholesalers__item-title">
                Northeast Medical Exchange-ADR
              </div>
              <div class="wholesalers__item-inventory">
                View Inventory (45)

              </div>
              <div class="wholesalers__item-btn">
                Apply
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="wholesalers__item">
            <div class="wholesalers__item-photo">
              <img :src="require('@/assets/images/profile-img.png')" alt="wholesalers-photo">
            </div>
            <div class="wholesalers__item-info">
              <div class="wholesalers__item-title">
                Northeast Medical Exchange-ADR
              </div>
              <div class="wholesalers__item-inventory">
                View Inventory (45)

              </div>
              <div class="wholesalers__item-btn">
                Apply
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="wholesalers__item">
            <div class="wholesalers__item-photo">
              <img :src="require('@/assets/images/profile-img.png')" alt="wholesalers-photo">
            </div>
            <div class="wholesalers__item-info">
              <div class="wholesalers__item-title">
                Northeast Medical Exchange-ADR
              </div>
              <div class="wholesalers__item-inventory">
                View Inventory (45)

              </div>
              <div class="wholesalers__item-btn">
                Apply
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="wholesalers__item">
            <div class="wholesalers__item-photo">
              <img :src="require('@/assets/images/profile-img.png')" alt="wholesalers-photo">
            </div>
            <div class="wholesalers__item-info">
              <div class="wholesalers__item-title">
                Northeast Medical Exchange-ADR
              </div>
              <div class="wholesalers__item-inventory">
                View Inventory (45)

              </div>
              <div class="wholesalers__item-btn">
                Apply
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="wholesalers__item">
            <div class="wholesalers__item-photo">
              <img :src="require('@/assets/images/profile-img.png')" alt="wholesalers-photo">
            </div>
            <div class="wholesalers__item-info">
              <div class="wholesalers__item-title">
                Northeast Medical Exchange-ADR
              </div>
              <div class="wholesalers__item-inventory">
                View Inventory (45)

              </div>
              <div class="wholesalers__item-btn">
                Apply
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
export default {
  components:{
    Header: () => import('../components/common/header'),
    Footer: () => import('../components/common/footer')
  }
}
</script>
<style lang="scss">
  @import "src/assets/styles/styles";
  .wholesalers{
    &__item{
      background: #FFFFFF;
      box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
      border-radius: 27px;
      margin-bottom: 30px;
    }
    &__item-photo{
      width: 100%;
      img{
        width: 100%;
        max-height: 220px;
        object-fit: cover;
        border-radius: 27px 27px 0 0;
      }
    }
    &__item-info{
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    &__item-title{
      font-family: 'Mont', Arial;
      font-weight: 800;
      font-size: 18px;
      text-transform: uppercase;
      color: $grey;
      margin-bottom: 15px;
      text-align: center;
      padding: 0 20px;
    }
    &__item-inventory{
      color: $blue;
      font-size: 14px;
      font-family: 'Inter', Arial;
      font-weight: 500;
    }
    &__item-btn{
      margin-top: 15px;
      background: #3B40F0;
      border-radius: 13px;
      color: $white;
      font-size: 16px;
      font-family: 'Inter', Arial;
      font-weight: 400;
      min-width: 165px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      overflow: hidden;
      padding: 10px;
      box-sizing: border-box;
    }
  }
</style>
