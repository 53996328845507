<template>
  <div class="formula">
    <Header/>
    <menuList
        :menu="productMenu"
        :toggleVacationMode="true"
        class="mb-2"
    />
    <div class="container mb-3">
      <div class="row">
        <div class="title col-sm-12">
          <div class="row">
            <span>Compounding Formula</span>
          </div>
        </div>
      </div>
    </div>
    <div class="formula__disclaimer">
      <div class="container">
        <div class="row">
          <h3>Disclaimer:</h3>
          <p>The information provided on this site is for educational and informational purposes and may not be relied upon to direct or provide care to a patient. This material is not intended as a substitute for consulting health care professionals. The health care professionals remain solely responsible to provide and direct patient care. The information contained on this site may not be up to date due to more recent medical developments. The preparation, dispensing, and administration of pharmaceuticals is and remains the responsibility of licensed medical professionals or pharmacists. Rxeed LLC and its owners/ managers assumes no liability for any injury and/or damage to persons from use of any information contained on this site. Patient situations will vary and some information may have become outdated as a result of more recent evidence or practice changes. All information displayed are from different sources and for educational and informational purposes and covered under the "Fair Use" Rule.</p>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <ul class="formula__hornbook">
            <li v-for="item in hornbook" :key="item">
              <p>{{item}}</p>
            </li>
        </ul>
      </div>
      <div class="row">
        <div class="formula__list mb-4">
          <div class="title col-sm-12 mb-2">
            <div class="row">
              <span>A</span>
            </div>
          </div>
          <div class="formula__item">
            Acepromazine Maleate 4 mg/mL Oral Suspension
          </div>
          <div class="formula__item">
            Acetaminophen 100 mg per mL Oral Liquid
          </div>
          <div class="formula__item">
            Acetaminophen 65 mg/mL
          </div>
          <div class="formula__item">
            Acetazolamide 200 mg Capsule Size #0
          </div>
          <div class="formula__item">
            acetaZOLAMIDE Oral Susp 25Mg/ML
          </div>
          <div class="formula__item">
            Acetic Acid 2% Topical
          </div>
        </div>
        <div class="formula__list mb-4">
          <div class="title col-sm-12 mb-2">
            <div class="row">
              <span>B</span>
            </div>
          </div>
          <div class="formula__item">
            Acepromazine Maleate 4 mg/mL Oral Suspension
          </div>
          <div class="formula__item">
            Acetaminophen 100 mg per mL Oral Liquid
          </div>
          <div class="formula__item">
            Acetaminophen 65 mg/mL
          </div>
          <div class="formula__item">
            Acetazolamide 200 mg Capsule Size #0
          </div>
          <div class="formula__item">
            acetaZOLAMIDE Oral Susp 25Mg/ML
          </div>
          <div class="formula__item">
            Acetic Acid 2% Topical
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <scroll-up :scroll-duration="1000" :scroll-y="250"></scroll-up>
  </div>
</template>
<script>
  import {mapGetters} from "vuex";

  export default {
    components:{
      Header: () => import('../../components/common/header'),
      Footer: () => import('../../components/common/footer'),
      menuList: () => import('../../components/common/menu-list'),
      ScrollUp: () => import('vue-scroll-up'),
    },
    data(){
      return{
        hornbook:[
          'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'
        ]
      }
    },
    computed:{
      ...mapGetters({
        productMenu:'general/productMenu'
      })
    }
  }
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
.formula{
  &__disclaimer{
    color: $white;
    background-color: $grey;
    padding: 30px 0 40px;
    margin-bottom: 30px;
    @media screen and (max-width: 720px){
      padding: 40px 20px;
    }
    h3{
      font-family: 'Inter', Arial;
      font-weight: 700;
      font-size: 16px;
    }
    p{
      font-family: 'Inter', Arial;
      font-weight: 400;
      font-size: 14px;
    }
  }
  &__list{
    position: relative;
    &:first-child{
      &:before{
        content: '';
        position: absolute;
        z-index:-1;
        pointer-events: none;
        user-select: none;
        height: 300px;
        width: 300px;
        right: 0px;
        top: 30%;
        background-color: $white-grey;
        border-radius: 50%;
      }
      &:after{
        content: '';
        position: absolute;
        z-index:-1;
        pointer-events: none;
        user-select: none;
        height: 40px;
        width: 40px;
        right: 300px;
        top: calc(30% + 450px);
        background-color: $white-grey;
        border-radius: 50%;
      }
    }
  }
  &__item{
    font-size: 16px;
  }
  &__hornbook{
    display: flex;
    width: 100%;
    padding: 20px 30px;
    background-color: $white-grey;
    border-radius: 27px;
    justify-content: space-between;
    margin-bottom: 30px;
    p{
      margin-bottom: 0;
      cursor: pointer;
      &:hover{
        opacity: 0.5;
      }
    }
  }
  .title{
    font-size: 64px;
  }
}
</style>
