<template>
  <div class="post-item">
    <Header/>
    <menuList
        :menu="productMenu"
        :toggleVacationMode="true"
        class="mb-2"
    />
    <div class="container mb-3">
      <div class="row">
        <div class="title col-sm-12">
          <div class="row">
            <span v-if='profileProduct'>Change Product</span>
            <span v-else>Add Product</span>
          </div>
        </div>
        <div class="row">
          <div class="input-block col-md-6 col-sm-12" >
            <input type="text" v-model="$v.form.ndc.$model" @input="searchNDC(form.ndc)"><span/>
            <span class="input-block__name" :class="form.ndc.length ?'active' : null" >NDC Number*</span>
            <small class="input-block__error" v-if="$v.form.ndc.$dirty && !$v.form.ndc.required">The ndc number must be required</small>
            <small class="input-block__error" v-if="showNotNdcRecord && !toggleChoiceNdc">Record not found</small>
            <div class="input-block__find" v-if="ndcList && !toggleChoiceNdc">
              <div class="input-block__find-item" v-for="(item, index) in ndcList.packaging" :key="index" @click="choiceNdc(item)">
                {{item.package_ndc}} | {{item.description}}
              </div>
            </div>
          </div>
          <div :class="ndcList ? 'input-block col-md-4 col-sm-12' : 'input-block col-md-6 col-sm-12'">
            <input type="text" v-model="$v.form.product_name.$model" readonly><span/>
            <span class="input-block__name" :class="form.product_name.length ?'active' : null" >Product Name*</span>
            <small class="input-block__error" v-if="$v.form.product_name.$dirty && !$v.form.product_name.required">The product name must be required</small>
          </div>
          <div class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.strength.$model" readonly><span/>
            <span class="input-block__name" :class="form.strength.length ?'active' : null" >strength*</span>
            <small class="input-block__error" v-if="$v.form.strength.$dirty && !$v.form.strength.required">The strength must be required</small>
          </div>
          <div class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.dosage_form.$model" readonly><span/>
            <span class="input-block__name" :class="form.dosage_form.length ?'active' : null" >Dosage Form*</span>
            <small class="input-block__error" v-if="$v.form.dosage_form.$dirty && !$v.form.dosage_form.required">The dosage form must be required</small>
          </div>
          <div class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.manufacturer_name.$model" readonly><span/>
            <span class="input-block__name" :class="form.manufacturer_name.length ?'active' : null" >Manufacturer*</span>
            <small class="input-block__error" v-if="$v.form.manufacturer_name.$dirty && !$v.form.manufacturer_name.required">The manufacturer must be required</small>
          </div>
          <div class="input-block col-md-4 col-sm-12" >
            <input type="month" id="start" name="start"
                   v-model="$v.form.expiration_date.$model"
                   min="2018-03" value="2018-05"><span/>
            <span class="input-block__name" :class="form.expiration_date.length ?'active' : 'active'" >Expiration Date* (month - year)</span>
            <small class="input-block__error" v-if="$v.form.expiration_date.$dirty && !$v.form.expiration_date.required">The expiration date must be required</small>
          </div>
          <div class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.lot.$model"><span/>
            <span class="input-block__name" :class="form.lot.length ?'active' : null" >Lot#*</span>
            <small class="input-block__error" v-if="$v.form.lot.$dirty && !$v.form.lot.required">The lot must be required</small>
          </div>

          <label class="select-block col-md-4 col-sm-12">
            <v-select
                v-model="$v.form.pack_quantity.$model"
                :options="[{id:0, title:'Full'}, {id:1, title: 'Partial'}]"
                :reduce="(i) => i.id"
                label="title"
                placeholder="Pack Quantity*"
                @input="changePackQuantity()"
            /><span class="bottom"/>
<!--            <small class="input-block__error" v-if="$v.form.pack_quantity.$dirty && !$v.form.pack_quantity.$error">The pack quantity must be required</small>-->
          </label>
          <div class="input-block col-md-4 col-sm-12">

            <input type="text" v-model="$v.form.quantity_in_stock.$model"  v-mask="'##'" :readonly="form.pack_quantity === 1"><span/>
            <span class="input-block__name" :class="form.quantity_in_stock ? 'active' : null">Quantity in Stock*</span>
            <small class="input-block__error" v-if="$v.form.quantity_in_stock.$dirty && !$v.form.quantity_in_stock.required">The quantity in stock must be required</small>
          </div>
          <div class="input-block col-md-4 col-sm-12" v-if="form.pack_quantity === 1">
            <input type="text" v-model="form.partial_quantity"  v-mask="'######'"><span/>
            <span class="input-block__name" :class="form.partial_quantity.length ?'active' : null" >Partial Quantity*</span>
            <!--            <small class="input-block__error" v-if="$v.form.partial_quantity.$dirty && !$v.form.partial_quantity.required">Partial quantity must be required</small>-->
          </div>
          <div class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.original_pack_size.$model" v-mask="'#############'"><span/>
            <span class="input-block__name" :class="form.original_pack_size ?'active' : null" >Original Pack Size*</span>
            <small class="input-block__error" v-if="$v.form.original_pack_size.$dirty && !$v.form.original_pack_size.required">The original pack size must be required</small>
          </div>
          <label class="select-block col-md-4 col-sm-12" v-if="packageConditionsList">
            <v-select
                v-model="form.package_condition_id"
                :options="packageConditionsList"
                :reduce="(i) => i.id"
                label="title"
                placeholder="Package Condition*"
            /><span class="bottom"/>
            <small class="input-block__error" v-if="$v.form.package_condition_id.$dirty && !$v.form.package_condition_id.$error">package condition is required</small>
          </label>
          <div class="input-block col-md-4 col-sm-12">
            <input type="text" v-model="$v.form.price.$model" v-mask="'#########'"><span/>
            <span class="input-block__name" :class="form.price ?'active' : null" >Price*</span>
            <small class="input-block__error" v-if="$v.form.price.$dirty && !$v.form.price.required">The price must be required</small>
          </div>
          <label class="radio-block col-md-8 col-sm-12 pt-1">
          <p>
            Is Fridge Product*
          </p>
          <label>
            <input type="radio" name="is_fridge" class="radio" value="1" v-model="form.is_fridge">
            <span>YES</span>
          </label>
          <label>
            <input type="radio" name="is_fridge" class="radio" value="0" v-model="form.is_fridge">
            <span>No</span>
          </label>
        </label>
        </div>
<!--        <div class="bank-info mt-3 mb-2">-->
<!--          <div class="bank-info__title">-->
<!--            Bank Information On File-->
<!--          </div>-->
<!--          <div class="bank-info__value">-->
<!--            <inline-svg :src="require(`@/assets/images/svg/check-ic.svg`)"></inline-svg>-->
<!--            <span>Account Ending With XXXXXXXX3711</span>-->
<!--          </div>-->
<!--        </div>-->
        <div class="text-info mt-2">
         <p> *Package Condition Information <br><br>

           Important:<br>
          Items must be in original packaging with visible NDC, lot #, expiration date and Serial Number.<br><br>

          Seller will document Serial Number (S/N) for each Product sold in an Open Prescription Bottle or Original Bottle on the Paper Purchase Order that is sent with the product(s) and the one kept on file at the pharmacy.<br><br>

          *It is the seller’s responsibility to accurately describe any damage or markings on the container.<br><br>

          *Seller attests the item has been stored and handled under the manufacturer’s temperature and storage requirements, was not purchased using a government discount program (i.e.340b) or preferred pricing, is not restricted to a limited distribution network, and was acquired from a manufacturer or wholesaler in compliance with the Drug Supply Chain Security Act (DSCSA) and posted item is not classified as a controlled substance.</p>
        </div>
        <button
            @click="sendProduct"
            class="btn blue mt-3"
        >
          <span v-if='profileProduct'>Change Product</span>
          <span v-else>Add Product</span>
        </button>
        <div class="title col-sm-12 mt-5 mb-3">
          <div class="row">
            <span>Similar Item Listed</span>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-3 col-sm-12">
            <div class="item-block">
            <div class="item-block__title">Tobradex ST Ophthalmic Suspension / Drops</div>
            <div class="item-block__ndc">NDC: 0469260230</div>
            <div class="item-block__info">
              <div class="item-block__info-item">
                <p>Pack Quantity</p>
                <span>full</span>
              </div>
              <div class="item-block__info-item">
                <p>Pack Size</p>
                <span>30</span>
              </div>
              <div class="item-block__info-item">
                <p>Strength</p>
                <span>50MG</span>
              </div>
              <div class="item-block__info-item">
                <p>Exp Date</p>
                <span>01-2024</span>
              </div>
              <div class="item-block__info-item">
                <p>Lot#</p>
                <span>A000035812</span>
              </div>
            </div>
            <div class="item-block__price">
              <p>Price</p>
              <span>$154,32</span>
            </div>
          </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="item-block">
            <div class="item-block__title">Tobradex ST Ophthalmic Suspension / Drops</div>
            <div class="item-block__ndc">NDC: 0469260230</div>
            <div class="item-block__info">
              <div class="item-block__info-item">
                <p>Pack Quantity</p>
                <span>full</span>
              </div>
              <div class="item-block__info-item">
                <p>Pack Size</p>
                <span>30</span>
              </div>
              <div class="item-block__info-item">
                <p>Strength</p>
                <span>50MG</span>
              </div>
              <div class="item-block__info-item">
                <p>Exp Date</p>
                <span>01-2024</span>
              </div>
              <div class="item-block__info-item">
                <p>Lot#</p>
                <span>A000035812</span>
              </div>
            </div>
            <div class="item-block__price">
              <p>Price</p>
              <span>$154,32</span>
            </div>
          </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="item-block">
            <div class="item-block__title">Tobradex ST Ophthalmic Suspension / Drops</div>
            <div class="item-block__ndc">NDC: 0469260230</div>
            <div class="item-block__info">
              <div class="item-block__info-item">
                <p>Pack Quantity</p>
                <span>full</span>
              </div>
              <div class="item-block__info-item">
                <p>Pack Size</p>
                <span>30</span>
              </div>
              <div class="item-block__info-item">
                <p>Strength</p>
                <span>50MG</span>
              </div>
              <div class="item-block__info-item">
                <p>Exp Date</p>
                <span>01-2024</span>
              </div>
              <div class="item-block__info-item">
                <p>Lot#</p>
                <span>A000035812</span>
              </div>
            </div>
            <div class="item-block__price">
              <p>Price</p>
              <span>$154,32</span>
            </div>
          </div>
          </div>
          <div class="col-md-3 col-sm-12">
            <div class="item-block">
            <div class="item-block__title">Tobradex ST Ophthalmic Suspension / Drops</div>
            <div class="item-block__ndc">NDC: 0469260230</div>
            <div class="item-block__info">
              <div class="item-block__info-item">
                <p>Pack Quantity</p>
                <span>full</span>
              </div>
              <div class="item-block__info-item">
                <p>Pack Size</p>
                <span>30</span>
              </div>
              <div class="item-block__info-item">
                <p>Strength</p>
                <span>50MG</span>
              </div>
              <div class="item-block__info-item">
                <p>Exp Date</p>
                <span>01-2024</span>
              </div>
              <div class="item-block__info-item">
                <p>Lot#</p>
                <span>A000035812</span>
              </div>
            </div>
            <div class="item-block__price">
              <p>Price</p>
              <span>$154,32</span>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import {required} from 'vuelidate/lib/validators'
  const _ = require('lodash');
  export default {
    components:{
      Header: () => import('../../components/common/header'),
      Footer: () => import('../../components/common/footer'),
      menuList: () => import('../../components/common/menu-list'),
    },
    data(){
      return{
        form:{
          ndc:'',
          product_name:'',
          strength:'',
          dosage_form:'',
          manufacturer_name:'',
          expiration_date:'',
          lot:'',
          pack_quantity:0,
          quantity_in_stock: 1,
          original_pack_size:'',
          package_condition_id:'',
          partial_quantity:'1',
          price:'',
          is_fridge:0
        },
        package_condition:[
          {
            id:0,
            title:'Select'
          },
          {
            id:1,
            title:'Sealed Original Container'
          },
          {
            id:2,
            title:'Open Original Container'
          },
          {
            id:3,
            title:'Open in a Prescription bottle'
          },
          {
            id:4,
            title:'Sealed Original bottle/Torn or label residue'
          },
          {
            id:5,
            title:'Open Original bottle/Torn or label residue'
          },
        ],
        showNotNdcRecord: false,
        toggleChoiceNdc: false,
      }
    },
    validations: {
      form: {
        ndc: {
          required
        },
        product_name: {
          required
        },
        strength: {
          required
        },
        dosage_form: {
          required
        },
        manufacturer_name: {
          required
        },
        expiration_date: {
          required
        },
        lot: {
          required
        },
        pack_quantity: {
          required
        },
        quantity_in_stock: {
          required
        },
        original_pack_size: {
          required
        },
        package_condition_id: {
          required
        },
        price: {
          required
        },
        is_fridge: {
          required
        },
      }
    },
    computed:{
      ...mapGetters({
        productMenu: 'general/productMenu',
        profileProduct: 'profile/profileProduct',
        ndcList: 'general/ndcList',
        packageConditionsList: 'general/packageConditionsList',
      })
    },
    created(){
      window.scrollTo(0, 0);
      this.getPackageConditionsList();
      if(this.profileProduct){
        this.form = this.profileProduct;
        this.form.package_condition_id = this.profileProduct.package_condition_id.id;
        this.form.quantity_in_stock = this.profileProduct.quantity_in_stock ? this.profileProduct.quantity_in_stock : 1;
        this.form.partial_quantity = this.profileProduct.partial_quantity ? this.profileProduct.partial_quantity : '1';
      }
    },
    methods:{
      searchNDC: _.debounce(function(item){
        this.toggleChoiceNdc = false;
        if(item.length > 5){
          this.searchNdcProduct(item).then(res=>{
            if(!res.status){
              this.showNotNdcRecord = true;
            }else{
              this.showNotNdcRecord = false
            }
          });
        }else{
          this.changeNdcList(null);
        }
      }, 500),
      choiceNdc(item){
        this.toggleChoiceNdc = true;
        this.form.dosage_form = this.ndcList.dosage_form;
        this.form.strength = this.ndcList.strength;
        this.form.product_name = this.ndcList.product_name;
        this.form.ndc = item.package_ndc;
        this.form.manufacturer_name = this.ndcList.manufacturer_name;
      },
      sendProduct(){
        if(this.profileProduct){
          this.changeProfileProduct({id: this.profileProduct.id, data: this.form}).then(res=>{
            if(res.status){
              this.$toasted.success('Product created success', {
                duration: 2000
              });
              setTimeout(()=>{
                this.$router.push('/products/my-posting')
              },2000)
            }else {
              this.$toasted.error(JSON.stringify(res.data), {
                duration: 2000
              });
            }
          })
        }else{
          this.$v.$touch();
          if(!this.$v.form.$error){
            this.addNewProfileProduct(this.form).then(res=>{
              if(res.status){
                this.$toasted.success('Product created success', {
                  duration: 2000
                });
                setTimeout(()=>{
                  this.$router.push('/products/my-posting')
                },2000)
              }else {
                this.$toasted.error(JSON.stringify(res.data), {
                  duration: 2000
                });
              }
            })
          }else{
            window.scrollTo(0, 0);
          }
        }
      },
      changePackQuantity(){
        if(this.form.pack_quantity){
          this.form.quantity_in_stock = 1;
        }else{
          this.form.quantity_in_stock = '';
        }
      },
      ...mapMutations({
        editProfileProduct: 'profile/editProfileProduct',
        changeNdcList: 'general/changeNdcList'
      }),
      ...mapActions({
        searchNdcProduct: 'general/searchNdcProduct',
        addNewProfileProduct: 'profile/addNewProfileProduct',
        changeProfileProduct: 'profile/changeProfileProduct',
        getPackageConditionsList: 'general/getPackageConditionsList',
      })
    },
    beforeDestroy() {
      this.editProfileProduct(null);
      this.changeNdcList(null);
    }
  }
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
.post-item{
  @media screen and (max-width: 720px){
    padding: 0 10px;
  }
  .btn{
    min-width: 280px;
  }
}
</style>
