<template>
  <div id="app">
    <router-view/>
    <Alert v-if="alertPopupShow"/>
    <TransactionPopup v-if="toggleTransactionPopup"/>
    <RejectBuyOrderPopup v-if="toggleRejectBuyOrderPopup"/>
    <ConfirmSellOrderPopup v-if="toggleConfirmSellOrderPopup"/>
    <RejectSellOrderPopup v-if="toggleRejectSellOrderPopup"/>
    <ConfirmBuyOrderPopup v-if="toggleConfirmBuyOrderPopup"/>
    <Loader v-if="toggleLoading"/>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components:{
    Alert: () => import('./components/popups/alert'),
    TransactionPopup: () => import('./components/popups/transaction'),
    RejectBuyOrderPopup: () => import('./components/popups/orders/rejectBuyOrder'),
    ConfirmSellOrderPopup: () => import('./components/popups/orders/confirmSellOrder'),
    RejectSellOrderPopup: () => import('./components/popups/orders/rejectSellOrder'),
    ConfirmBuyOrderPopup: () => import('./components/popups/orders/confirmBuyOrder'),
    Loader: () => import('./components/loader/index')
  },
  created() {
    this.getTextBlock();
    this.getSocialLinks();
    if(localStorage.getItem('userToken') && !localStorage.getItem('profile')){
      this.getProfile().then(res=>{
        if(!res.status){
          this.$toasted.error(res.data,{
            duration: 3000
          });
        }
      });
    }else if(localStorage.getItem('userToken') && localStorage.getItem('profile')){
      this.changeProfile(JSON.parse(localStorage.getItem('profile')))
    }
  },
  computed:{
    ...mapGetters({
      alertPopupShow:'general/alertPopupShow',
      toggleTransactionPopup:'general/toggleTransactionPopup',
      toggleRejectBuyOrderPopup:'general/toggleRejectBuyOrderPopup',
      toggleConfirmSellOrderPopup:'general/toggleConfirmSellOrderPopup',
      toggleRejectSellOrderPopup:'general/toggleRejectSellOrderPopup',
      toggleConfirmBuyOrderPopup:'general/toggleConfirmBuyOrderPopup',
      toggleLoading:'general/toggleLoading',
    })
  },
  methods:{
    ...mapActions({
      getTextBlock: 'landing/getTextBlock',
      getSocialLinks: 'landing/getSocialLinks',
      getProfile: 'profile/getProfile',
    }),
    ...mapMutations({
      changeProfile:'profile/changeProfile'
    })
  }
}
</script>


