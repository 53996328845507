BuyOrders.vue<template>
  <div class="buy-orders">
    <Header/>
    <menuList
        :menu="ordersMenu"
        :toggleVacationMode="false"
        class="mb-2"
    />
    <div class="container mb-3">
    </div>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  components:{
    Header: () => import('../../components/common/header'),
    Footer: () => import('../../components/common/footer'),
    menuList: () => import('../../components/common/menu-list'),
  },
  data() {
    return {}
  },
  computed:{
    ...mapGetters({
      ordersMenu:'general/ordersMenu'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
</style>
