import axios from "axios";


axios.defaults.headers = {
    'Accept':'application/json',
}

const state = {
    textBlock: {},
    aboutUsBlocks: {},
    faqList:null,
    testimonials:null,
    socialLinks:null,
    preLoading:true,
}

const getters = {
    textBlock: state => state.textBlock,
    aboutUsBlocks: state => state.aboutUsBlocks,
    faqList: state => state.faqList,
    testimonials: state => state.testimonials,
    socialLinks: state => state.socialLinks,
    preLoading: state => state.preLoading,
}

const actions = {
    getTextBlock: async ({commit}) => {
        try{
            const result = await axios.get('/landing/text-blocks?page=1&per_page=100');
            commit('changeTextBlock', result.data.data)
        }catch (e){
            console.log(e)
            return e.response.data
        }
    },
    getAboutUsBlocks: async ({commit}) => {
        try{
            const result = await axios.get('/landing/about-us');
            commit('changeAboutUsBlocks', result.data)
        }catch (e){
            console.log(e)
            return e.response.data
        }
    },
    getFaqList: async ({commit}) => {
        try{
            const result = await axios.get('/landing/faq?page=1&per_page=100');
            commit('changeFaqList', result.data)
        }catch (e){
            console.log(e)
            return e.response.data
        }
    },
    getTestimonials: async ({commit}) => {
        try{
            const result = await axios.get('/landing/testimonials');
            commit('changeTestimonials', result.data)
        }catch (e){
            console.log(e)
            return e.response.data
        }
    },
    sendTestimonial: async (ctx, payload) => {
        try{
            const result = await axios.post('/landing/testimonials', payload);
            return {
                status: true,
                data: result
            }
        }catch (e){
            return {
                status: false,
                data:e.response.data
            }
        }
    },
    sendContactsUs: async (ctx, payload) => {
        try{
            const result = await axios.post('/landing/contact-us', payload);
            return {
                status: true,
                data: result
            }
        }catch (e){
            return {
                status: false,
                data:e.response.data
            }
        }
    },
    getSocialLinks: async ({commit}) => {
        try{
            const result = await axios.get('/landing/social-links');
            commit('changeSocialLinks', result.data)
        }catch (e){
            console.log(e)
            return e.response.data
        }
    },
}

const mutations = {
    changeTextBlock(state, result){
        state.preLoading = true;
        result.map(blockItem => {
            if(Object.keys(state.textBlock).length && Object.keys(state.textBlock).includes(blockItem.group)) {
                state.textBlock[blockItem.group][blockItem.key] = blockItem.value;
            }else{
                state.textBlock[blockItem.group] = {[blockItem.key]: blockItem.value};
            }
        });
        state.preLoading = false;
    },
    changeAboutUsBlocks(state, result){
        state.aboutUsBlocks = result;
    },
    changeFaqList(state, result){
        state.faqList = result;
    },
    changeTestimonials(state, result){
        state.testimonials = result;
    },
    changeSocialLinks(state, result){
        state.socialLinks = result;
    },
}

export default {
    getters,
    state,
    actions,
    mutations,
    namespaced: true
}
