<template>
  <div class="wishlist">
    <Header/>
    <div class="container mt-4 mb-3">
      <div class="row">
        <div class="title col-sm-12">
          <div class="row">
            <span>wishlist</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-2" v-if="wishlist && wishlist.length">
      <div class="row">
        <Search/>
      </div>
    </div>
    <div class="wishlist__list">

      <div class="container" v-if="wishlist && wishlist.length">
        <div class="row">
          <div class="title white col-sm-12 col-md-8 mb-2">
            <div class="row">
              <span>Review your product</span>
            </div>
          </div>
          <div class="search-sort col-sm-12 col-md-4">
            <p class="active">new orders</p>
            <p>old orders</p>
          </div>
          <WishItem
              v-for="item in wishlist"
              :key="item.id"
              :item="item"
              class="col-sm-12 mb-2"
          />
        </div>
      </div>
      <div class="container mb-5" v-else>
        <div class="row">
          <h2 class="wishlist__empty">Your wish list is empty</h2>
        </div>
      </div>
    </div>
    <pagination class="mb-5" v-if="wishlist && wishlist.length > 10"/>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters} from "vuex";
export default {
  components:{
    Header: () => import('@/components/common/header'),
    Footer: () => import('@/components/common/footer'),
    WishItem: () => import('@/components/wishlist/wishItem'),
    Search: () => import('@/components/wishlist/search'),
    pagination: () => import('@/components/common/pagination'),
  },
  computed:{
    ...mapGetters({
      ordersMenu:'general/ordersMenu',
      wishlist: 'general/wishlist'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/styles";
.wishlist{
  &__list{
    position: relative;
    padding: 45px 0;
    margin-bottom: 60px;
    @media screen and (max-width: 992px){
      padding: 35px 15px;
    }
    &:before{
      content: '';
      position: absolute;
      height: 400px;
      width: 100%;
      left: 0;
      top: 0;
      background-color: $grey;
      z-index: -1;
    }
  }
  &__empty{
    width: 100%;
    border-radius: 27px;
    background-color: $white-grey;
    padding: 15px;
    display: flex;
    justify-content: center;
    font-family: 'Inter', Arial;
    font-size: 18px;
    font-weight: 700;
    border:1px solid $grey;
  }
}
</style>
