// import axios from "axios";

const state = {
    searchList:[],
}

const getters = {
    searchList: state => state.searchList,
}

const actions = {
}

const mutations = {
    changeSearchList(state, result){
        state.searchList = result;
    },
}

export default {
    getters,
    state,
    actions,
    mutations,
    namespaced: true
}
