import Vue from 'vue';
import Router from 'vue-router'
import LandingPage from "./pages/LandingPage";
import Dashboard from "./pages/Dashboard";
import Profile from "./pages/Profile";
import Products from "./pages/Products";
import MyPosting from "./pages/products/MyPosting";
import PostItem from "./pages/products/PostItem";
import CompoundingFormula from "./pages/products/CompoundingFormula";
import Orders from "./pages/Orders";
import BuyOrders from "./pages/orders/BuyOrders";
import SellOrders from "./pages/orders/SellOrders";
import Transaction from "./pages/orders/Transaction";
import ManufacturerCoupons from "./pages/orders/ManufacturerCoupons";
import Wholesalers from "./pages/Wholesalers";
import Wishlist from "./pages/Wishlist";
import Cart from "./pages/Cart";
import VerifyEmail from "./pages/VerifyEmail";
import ResetPassword from "./pages/ResetPassword";
import PathNotFound from './pages/404'
import AddToWishList from "./pages/AddToWishList";
import StripeReturn from "./pages/StripeReturn";

Vue.use(Router);


const isAuthentificated = (to, from, next) =>{
    if(localStorage.getItem('userToken')){
        next();
    }else{
        next('/')
    }
}
const isNotAuthentificated = (to, from, next) =>{
    if(!localStorage.getItem('userToken')){
        next();
    }else{
        next('/dashboard')
    }
}

export default new Router({

    mode: 'history',
    routes: [
        {
            path:'/',
            name: 'LandingPage',
            component: LandingPage,
            beforeEnter: isNotAuthentificated,
        },
        {
            path:'/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/profile',
            name: 'Profile',
            component: Profile,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/products',
            name: 'Products',
            component: Products,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/products/my-posting',
            name: 'MyPosting',
            component: MyPosting,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/products/post-item',
            name: 'PostItem',
            component: PostItem,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/orders/buy-orders',
            name: 'BuyOrders',
            component: BuyOrders,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/orders/sell-orders',
            name: 'SellOrders',
            component: SellOrders,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/orders/sell-orders/:id',
            name: 'SellOrders',
            component: SellOrders,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/orders/transaction',
            name: 'Transaction',
            component: Transaction,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/orders/manufacturer-coupons',
            name: 'ManufacturerCoupons',
            component: ManufacturerCoupons,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/products/compounding-formula',
            name: 'CompoundingFormula',
            component: CompoundingFormula,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/orders',
            name: 'Orders',
            component: Orders,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/wholesalers',
            name: 'Wholesalers',
            component: Wholesalers,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/wishlist',
            name: 'Wishlist',
            component: Wishlist,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/cart',
            name: 'Cart',
            component: Cart,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/add-to-wishList',
            name: 'AddToWishList',
            component: AddToWishList,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/stripe-return/:id',
            name: 'StripeReturn',
            component: StripeReturn,
            beforeEnter: isAuthentificated,
        },
        {
            path:'/verify-email/:id',
            name: 'VerifyEmail',
            component: VerifyEmail,
            beforeEnter: isNotAuthentificated,
        },
        {
            path:'/reset-password/:id',
            name: 'ResetPassword',
            component: ResetPassword,
            beforeEnter: isNotAuthentificated,
        },
        {
            path: '/:pathMatch(.*)*',
            component: PathNotFound
        },
    ]
})
