import axios from "axios";
import qs from 'qs';

const state = {
    toggleRegistration: false,
    toggleLogin: false,
    toggleForgotPassword: false,
    toggleChangePassword: false,
    alertPopupShow: false,
    alertPopupContent:false,
    productMenu:[
        {
            title:'Post Item',
            link:'/products/post-item'
        },
        {
            title:'My posting',
            link:'/products/my-posting'
        },
        // {
        //     title:'Compounding Formula',
        //     link:'/products/compounding-formula'
        // }
    ],
    ordersMenu:[
        {
            title:'Buy Orders',
            link:'/orders/buy-orders'
        },
        {
            title:'Sell Orders',
            link:'/orders/sell-orders'
        },
        {
            title:'Transaction',
            link:'/orders/transaction'
        },
        // {
        //     title:'Manufacturer-coupons',
        //     link:'/orders/manufacturer-coupons'
        // }
    ],
    ndcList: null,
    packageConditionsList: null,
    wishlist: null,
    toggleTransactionPopup: false,
    toggleRejectBuyOrderPopup: false,
    toggleConfirmSellOrderPopup: false,
    toggleRejectSellOrderPopup: false,
    toggleConfirmBuyOrderPopup: false,
    wishItem: null,
    toggleLoading: false,
};

const getters = {
    toggleRegistration: state => state.toggleRegistration,
    toggleLogin: state => state.toggleLogin,
    toggleForgotPassword: state => state.toggleForgotPassword,
    toggleChangePassword: state => state.toggleChangePassword,
    packageConditionsList: state => state.packageConditionsList,
    alertPopupShow: state => state.alertPopupShow,
    alertPopupContent: state => state.alertPopupContent,
    productMenu: state => state.productMenu,
    ordersMenu: state => state.ordersMenu,
    ndcList: state => state.ndcList,
    wishlist: state => state.wishlist,
    toggleTransactionPopup: state => state.toggleTransactionPopup,
    toggleRejectBuyOrderPopup: state => state.toggleRejectBuyOrderPopup,
    toggleConfirmSellOrderPopup: state => state.toggleConfirmSellOrderPopup,
    toggleRejectSellOrderPopup: state => state.toggleRejectSellOrderPopup,
    toggleConfirmBuyOrderPopup: state => state.toggleConfirmBuyOrderPopup,
    wishItem: state => state.wishItem,
    toggleLoading: state => state.toggleLoading,
};

const actions = {
    uploadFile: async (ctx, payload) => {
        const data = new FormData()
        data.append('file', payload)
        try {
            const result = await axios.post('/upload', data)
            return result
        }catch (e){
            console.log(e)
        }
    },
    searchNdcProduct: async({commit}, payload) => {
        try{
            const result = await axios.get(`/drugs/${payload}`)
            commit('changeNdcList', result.data.data)
            return{
                status: true,
                data: result.data
            }
        }catch(e){
            commit('changeNdcList', null)
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getPackageConditionsList: async({commit}) => {
        try{
            const result = await axios.get(`/products/package-conditions?page=1&per_page=100`)
            commit('changePackageConditionsList', result.data.data)
            return{
                status: true,
                data: result.data
            }
        }catch(e){
            commit('changeNdcList', null)
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getWishlist: async({commit}, payload) => {
        try{
            const result = await axios.get(`/wish-products`, {
                params: payload,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            })
            commit('changeWishlist', result.data.data);
            return{
                status: true,
                data: result.data
            }
        }catch(e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    sendWishlist: async(ctx, payload) => {
        try{
            const result = await axios.post(`/wish-products`, payload)
            return{
                status: true,
                data: result.data
            }
        }catch(e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    removeWishItem: async(ctx, id) => {
        try{
            const result = await axios.delete(`/wish-products/${id}`)
            return{
                status: true,
                data: result.data
            }
        }catch(e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
}

const mutations = {
    changeToggleRegistration(state, status){
        state.toggleRegistration = status;
    },
    changeToggleLogin(state, status){
        state.toggleLogin = status;
    },
    changeToggleForgotPassword(state, status){
        state.toggleForgotPassword = status;
    },
    changeToggleChangePassword(state, status){
        state.toggleChangePassword = status;
    },
    toggleAlertPopup(state, result){
        state.alertPopupShow = result.status;
        state.alertPopupContent = result.content;
    },
    changeNdcList(state, result){
        state.ndcList = result;
    },
    changePackageConditionsList(state, result){
        state.packageConditionsList = result;
    },
    changeWishlist(state, result){
        state.wishlist = result;
    },
    changeToggleTransactionPopup(state, result){
        state.toggleTransactionPopup = result;
    },
    changeToggleRejectBuyOrderPopup(state, result){
        state.toggleRejectBuyOrderPopup = result;
    },
    changeToggleConfirmBuyOrderPopup(state, result){
        state.toggleConfirmBuyOrderPopup = result;
    },
    changeToggleConfirmSellOrderPopup(state, result){
        state.toggleConfirmSellOrderPopup = result;
    },
    changeToggleRejectSellOrderPopup(state, result){
        state.toggleRejectSellOrderPopup = result;
    },
    changeWishItem(state, data){
        state.wishItem = data;
    },
    changeToggleLoading(state, status){
        state.toggleLoading = status;
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true,
}
