import axios from "axios";

const state = {
    statesList:[],
    questionsList:[],
    businessTypeList:[],
}

const getters = {
    statesList: state => state.statesList,
    questionsList: state => state.questionsList,
    businessTypeList: state => state.businessTypeList,
}

const actions = {
    getStatesList: async({commit}) => {
        try {
            const result = await axios.get('/states?page=1&per_page=100&sort=title&order=asc')
            commit('changeStateList', result.data)
        }catch (e){
            console.log(e)
        }
    },
    getBusinessTypeList: async({commit}) => {
        try {
            const result = await axios.get('/business-types?page=1&per_page=100&sort=title&order=asc')
            commit('changeBusinessTypeList', result.data)
        }catch (e){
            console.log(e)
        }
    },
    getRegistrationQuestions: async({commit}) => {
        try {
            const result = await axios.get('/registration-questions?page=1&per_page=100')
            commit('changeQuestionsList', result.data)
        }catch (e){
            console.log(e)
        }
    },
    sendRegistration: async(ctx, payload) => {
        try {
            const result = await axios.post('/register', payload);
            return{
                status: true,
                data: result
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    sendVerifyEmail: async(ctx, payload) => {
        try {
            const result = await axios.post('/verify-email', payload);
            return{
                status: true,
                data: result
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    sendRemindPassword: async(ctx, payload) => {
        try {
            const result = await axios.post('/password/remind', payload);
            return{
                status: true,
                data: result
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    sendResetPassword: async(ctx, payload) => {
        try {
            const result = await axios.post('/password/reset', payload);
            return{
                status: true,
                data: result
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    sendAuth: async(ctx, payload) => {
        try {
            const result = await axios.post('/login', payload);
            localStorage.setItem('userToken', result.data.access_token);
            return{
                status: true,
                data: result
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },

}

const mutations = {
    changeStateList(state, result){
        state.statesList = result.data;
    },
    changeBusinessTypeList(state, result){
        state.businessTypeList = result.data;
    },
    changeQuestionsList(state, result){
        state.questionsList = result.data;
    }
}

export default {
    getters,
    state,
    actions,
    mutations,
    namespaced: true
}
