<template>
  <div class="not-found">
    <h1>404</h1>
    <p>Page not found.</p>
    <router-link to="/" class="verify-email__link">Back to site</router-link>
  </div>
</template>
<script>
export default {}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.not-found{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  height: 100vh;
  width: 100vw;
  h1{
    font-size: 66px;
    font-family: 'Mont';
    font-weight: 900;
  }
  p{
    margin-bottom: 50px;
  }
}
</style>
