export default {
    data(){
        return{
            page:1,
            per_page: 10,
        }
    },
    methods:{
        changePage(page){
            this.page = page
        },
        changePerPage(perPage){
            this.per_page = perPage;
        },
        toNextPage(){
            this.page++;
        },
        toPrevPage(){
            this.page--;
        },
    }
}
