import dashboard from './modules/dashboard'
import general from './modules/general'
import landing from './modules/landing'
import profile from "./modules/profile";
import product from "./modules/product";
import orders from "./modules/orders";
import auth from './modules/auth'

export default {
    dashboard,
    product,
    general,
    landing,
    profile,
    orders,
    auth,
}
