<template>
  <div class="wishlist">
    <Header/>
    <div class="container mt-4 mb-3">
      <div class="row">
        <div class="title col-sm-12 mb-2">
          <span>Add to wishlist</span>
        </div>
        <div class="container">
          <div class="row">
            <label class="input-block  col-xl-6 col-md-6 col-sm-12">
              <input type="text"  v-model="$v.form.ndc.$model" @input="searchNDC(form.ndc)"><span/>
              <span class="input-block__name" :class="form.ndc.length ?'active' : null" >NDC Number*</span>
              <small class="input-block__error" v-if="$v.form.ndc.$dirty && !$v.form.ndc.required">The ndc number must be required</small>
              <small class="input-block__error" v-if="showNotNdcRecord && !toggleChoiceNdc">Record not found</small>
              <div class="input-block__find" v-if="ndcList && !toggleChoiceNdc">
                <div class="input-block__find-item" v-for="(item, index) in ndcList.packaging" :key="index" @click="choiceNdc(item)">
                  {{item.package_ndc}} | {{item.description}}
                </div>
              </div>
            </label>
            <label class="input-block col-xl-6 col-md-6 col-sm-12">
              <input type="text"  v-model="$v.form.product_name.$model" readonly><span/>
              <span class="input-block__name" :class="form.product_name.length ?'active' : null" >Product name*</span>
              <small class="input-block__error" v-if="$v.form.product_name.$dirty && !$v.form.product_name.required">The product name must be required</small>
            </label>
            <label class="input-block col-xl-6 col-md-6 col-sm-12">
              <input type="text"  v-model="$v.form.strength.$model" readonly><span/>
              <span class="input-block__name" :class="form.strength.length ?'active' : null" >Strength*</span>
              <small class="input-block__error" v-if="$v.form.strength.$dirty && !$v.form.strength.required">The strength be required</small>
            </label>
            <label class="input-block col-xl-6 col-md-6 col-sm-12">
              <input type="text"  v-model="$v.form.dosage_form.$model" readonly><span/>
              <span class="input-block__name" :class="form.dosage_form.length ?'active' : null" >Dosage form*</span>
              <small class="input-block__error" v-if="$v.form.dosage_form.$dirty && !$v.form.dosage_form.required">The dosage form must be required</small>
            </label>
            <label class="input-block col-xl-6 col-md-6 col-sm-12">
              <input type="text"  v-model="$v.form.pack_size.$model"><span/>
              <span class="input-block__name" :class="form.pack_size.length ?'active' : null" >Pack size*</span>
              <small class="input-block__error" v-if="$v.form.pack_size.$dirty && !$v.form.pack_size.required">The pack size must be required</small>
            </label>
            <label class="input-block col-xl-6 col-md-6 col-sm-12">
              <input type="text"  v-model="$v.form.manufacturer_name.$model" readonly><span/>
              <span class="input-block__name" :class="form.manufacturer_name.length ?'active' : null" >Manufacturer*</span>
              <small class="input-block__error" v-if="$v.form.manufacturer_name.$dirty && !$v.form.manufacturer_name.required">The manufacturer must be required</small>
            </label>
              <div class="col-xl-6 col-md-6 col-sm-12">
                <div class="row">
                  <label class="input-block col-xl-6 col-md-6 col-sm-12">
                    <input type="text"  v-model="price[0]"><span/>
                    <span class="input-block__name active">Price from</span>
                  </label>
                  <label class="input-block col-xl-6 col-md-6 col-sm-12">
                    <input type="text"  v-model="price[1]"><span/>
                    <span class="input-block__name active">Price to</span>
                  </label>
                  <div class="col-sm-12">
                    <VueSimpleRangeSlider
                        :min="0"
                        :max="1000"
                        v-model="price"
                    />
                  </div>
                </div>
              </div>
            <div class="col-sm-12 d-flex justify-end">
              <button class="btn blue mr-3" @click="addToWishList()">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters, mapActions} from "vuex";
import {required} from "vuelidate/lib/validators";
import VueSimpleRangeSlider from 'vue-simple-range-slider';
import 'vue-simple-range-slider/dist/vueSimpleRangeSlider.css'
import _ from "lodash";

export default {
  components: {
    Header: () => import('@/components/common/header'),
    Footer: () => import('@/components/common/footer'),
    VueSimpleRangeSlider
  },
  data() {
    return {
      form:{
        ndc: '',
        product_name: '',
        strength: '',
        dosage_form: '',
        pack_size: '',
        manufacturer_name: '',
        price_from: 0,
        price_to: 1000,
      },
      price:[0, 1000],
      showNotNdcRecord: false,
      toggleChoiceNdc: false,
    }
  },
  validations: {
    form: {
      ndc: {
        required
      },
      product_name: {
        required
      },
      strength: {
        required
      },
      dosage_form: {
        required
      },
      pack_size: {
        required
      },
      manufacturer_name: {
        required
      },
    }
  },
  computed: {
    ...mapGetters({
      ordersMenu: 'general/ordersMenu',
      wishItem: 'general/wishItem',
      ndcList: 'general/ndcList',
    })
  },
  created() {
    window.scrollTo(0,0);
    this.form.ndc = this.wishItem.ndc;
    this.form.product_name = this.wishItem.product_name;
    this.form.strength = this.wishItem.strength;
    this.form.dosage_form = this.wishItem.dosage_form;
    this.form.pack_size = this.wishItem.original_pack_size;
    this.form.manufacturer_name = this.wishItem.manufacturer_name;
    this.price[1] = this.wishItem.price;
  },
  methods:{
    searchNDC: _.debounce(function(item){
      this.toggleChoiceNdc = false;
      if(item.length > 5){
        this.searchNdcProduct(item).then(res=>{
          if(!res.status){
            this.showNotNdcRecord = true;
          }else{
            this.showNotNdcRecord = false
          }
        });
      }else{
        this.changeNdcList(null);
      }
    }, 500),
    choiceNdc(item){
      this.toggleChoiceNdc = true;
      this.form.dosage_form = this.ndcList.dosage_form;
      this.form.strength = this.ndcList.strength;
      this.form.product_name = this.ndcList.product_name;
      this.form.ndc = item.package_ndc;
      this.form.manufacturer_name = this.ndcList.manufacturer_name;
    },
    addToWishList(){
      this.$v.$touch();
      this.form.price_from = this.price[0];
      this.form.price_to = this.price[1];
      if(!this.$v.form.$error){
        this.sendWishlist(this.form).then(res=>{
          if(res.status){
            this.getWishlist();
          this.$toasted.success('Success added to wishlist',{
            duration: 3000
          })
          this.cleanWishlistForm();
          this.$v.$reset();
          }else{
            this.$toasted.error(JSON.stringify(res.data), {
              duration: 3000
            })
          }
        })
      }
    },
    cleanWishlistForm(){
      this.form = {
        ndc: '',
        product_name: '',
        strength: '',
        dosage_form: '',
        pack_size: '',
        manufacturer_name: '',
        price_from: 0,
        price_to: 1000,
      }
        this.price = [0, 1000]
    },
    ...mapActions({
        sendWishlist: 'general/sendWishlist',
        getWishlist: 'general/getWishlist',
        searchNdcProduct: 'general/searchNdcProduct',
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/styles";
.multirange-slider{
  width: 100%;
  height: 50px;
  background-color: #cccccc;
}
</style>
