<template>
  <div>
    <Header class="mb-2"/>
    <div class="profile" v-if="profile">
      <div class="container">
        <div class="row">
          <div class="profile__title col-md-12">
            {{ profile.manager_contact_name }}
          </div>
          <div class="col-md-5 col-sm-12">
            <div class="profile__image">
              <!--                <img src="@/assets/images/profile-img.png" alt="profile">-->
              <img v-if="profile.logo && profile.logo.medium" :src="profile.logo.medium.link" alt="profile">
              <span class="profile__without-photo" v-else alt="profile">No Photo</span>
            </div>
          </div>
          <div class="profile__info-list col-md-7 col-sm-12 mb-4">
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Phone:</div>
              <div class="profile__info-value">{{ profile.phone }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Mobile No#:</div>
              <div class="profile__info-value">{{ profile.mobile_phone }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Fax:</div>
              <div class="profile__info-value">{{ profile.fax }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Email:</div>
              <div class="profile__info-value">{{ profile.email }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Pharmacy NCPDP:</div>
              <div class="profile__info-value">{{ profile.pharmacy_ncpdp }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Pharmacy NPI:</div>
              <div class="profile__info-value">{{ profile.pharmacy_npi }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">DEA#</div>
              <div class="profile__info-value">{{ profile.dea }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Pharmacy State License #:</div>
              <div class="profile__info-value">{{ profile.pharmacy_state_license_number }}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Federal Tax ID:</div>
              <div class="profile__info-value">
                <span v-if="profile.federal_tax_id">{{ profile.federal_tax_id }}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Store:</div>
              <div class="profile__info-value">{{profile.store}}</div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">Web Site Link:</div>
              <div class="profile__info-value">
                <span v-if="profile.website_link">{{profile.website_link}}</span>
                <span v-else>-</span>
              </div>
            </div>
            <div class="profile__info-item">
              <div class="profile__info-name w-50">States Pharmacy is Licensed in</div>
              <div class="profile__info-value" v-if="profile.pharmacy_states.length">
                  <span v-for="state in profile.pharmacy_states" :key="state.id">
                    {{state.title}} <span v-if="profile.pharmacy_states.length > 1">, </span>
                  </span>
              </div>
              <div class="profile__info-value" v-else>
                -
              </div>
            </div>
            <label class="checkbox-block">
              <input
                  type="checkbox"
                  class="checkbox"
                  v-model="profile.on_vacation"
                  @input="changeVacationMode"
              >
              <span>Vacation Mode</span>
            </label>
          </div>
          <div class="profile__title col-md-12">
            Payment
          </div>
          <div class="profile__form col-md-12 mb-4">
            <div class="row">
              <button class="btn blue mr-3"
                      v-if="!profile.is_payout_method_connected"
                      @click="connectPayoutAccount()"
              >
                Connect payout account
              </button>
              <span class="profile__connected-status" v-else>Payout account connected</span>
              <button class="btn blue mr-3"
                      v-if="!profile.is_payment_method_connected"
                      @click="submitPaymentMethod()"
              >
                Connect payment method
              </button>
              <span class="profile__connected-status" v-else-if="!profile.is_payment_method_connected && checkoutPaymentMethod">
                  we checkout your payment method connected
                </span>
              <span class="profile__connected-status" v-else>Payment method connected</span>

            </div>
          </div>
          <div class="profile__title col-md-12">
            Change your Password
          </div>
          <div class="profile__form col-md-12 mb-4">
            <div class="row">
              <label class="input-block col-md-3 col-sm-12">
                <input type="password" v-model="$v.form.old_password.$model"><span/>
                <span class="input-block__name" :class="form.old_password.length ?'active' : null" >Current Password*</span>
                <small class="input-block__error" v-if="$v.form.old_password.$dirty && !$v.form.old_password.required">password is required</small>
              </label>
              <label class="input-block col-md-3 col-sm-12">
                <input type="password" v-model="$v.form.password.$model" @input="validatePasswordForm()"><span/>
                <span class="input-block__name" :class="form.password.length ?'active' : null">password*</span>
                <small class="input-block__error" v-if="$v.form.password.$dirty && !$v.form.password.minLength">The password must be at least 6 characters</small>
                <small class="input-block__error" v-if="$v.form.password.$dirty && !$v.form.password.maxLength">The password must be at biggest 40 characters</small>
                <small class="input-block__error" v-if="$v.form.password.$dirty && this.passwordRegexError && $v.form.password.minLength && $v.form.password.maxLength">The password must by including uppercase and lowercase letters and digits</small>
              </label>
              <label class="input-block col-md-3 col-sm-12">
                <input type="password" v-model="$v.form.password_confirmation.$model"><span/>
                <span class="input-block__name" :class="form.password_confirmation.length ?'active' : null" >Confirm Password*</span>
                <small class="input-block__error" v-if="$v.form.password_confirmation.$dirty && $v.form.password_confirmation.$error">password confirmation is required</small>
              </label>
              <label class="input-block col-md-3 col-sm-12">

                <button class="btn blue mr-3"
                        @click="changePassword()"
                >
                  OK
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="profile__section" v-if="profile" :class="!profile.addresses || profile.addresses.length === 0 ? 'no-bg' : null ">
      <div class="container">
        <div class="row">
          <div class="col-md-6 col-sm-12 mb-5" v-for="(address, index) in profile.addresses" :key="index">
            <div class="profile__title white">
              {{ address.type }} Address
            </div>
            <div class="profile__info-block white">
              <div class="profile__info-list">
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">Physical Address:</div>
                  <div class="profile__info-value">{{ address.address }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">City:</div>
                  <div class="profile__info-value">{{ address.city }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">State:</div>
                  <div class="profile__info-value">{{ address.state.title }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">Zip</div>
                  <div class="profile__info-value">{{ address.zip_code }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-sm-12 mb-5" v-if="profile && profile.addresses.length === 1">
            <div class="profile__title white">
              Mailing Address
            </div>
            <div class="profile__info-block white">
              <div class="profile__info-list">
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">Physical Address:</div>
                  <div class="profile__info-value">{{ profile.addresses[0].address }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">City:</div>
                  <div class="profile__info-value">{{ profile.addresses[0].city }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">State:</div>
                  <div class="profile__info-value">{{ profile.addresses[0].state.title }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-35">Zip</div>
                  <div class="profile__info-value">{{ profile.addresses[0].zip_code }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-sm-12">
            <div class="profile__title">
              Other Details
            </div>
            <div class="profile__info-block">
              <div class="profile__info-list">
                <div class="profile__info-item">
                  <div class="profile__info-name w-40">Pharmacy Manager (PIC)/Contact Name:</div>
                  <div class="profile__info-value">{{profile.manager_contact_name}}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-40">Contract Manager:</div>
                  <div class="profile__info-value">{{ profile.contract_manager }}</div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-40">Select One or More That Match Your Business:</div>
                  <div class="profile__info-value" v-if="profile.business_types.length">
                    <span v-for="business in profile.business_types" :key="business.id">
                      {{business.title}}<span v-if="profile.business_types.length > 1">, </span>
                    </span>
                  </div><div class="profile__info-value" v-else>
                  -
                </div>
                </div>
                <div class="profile__info-item">
                  <div class="profile__info-name w-40">340B Status</div>
                  <div class="profile__info-value"><span v-if="profile.b340_status">Yes</span><span v-else>No</span></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="profile__section" v-if="userAnswers">
      <div class="container">
        <div class="row">
          <!--          <div class="col-md-12 col-sm-12 mb-5">-->
          <!--            <div class="profile__title white">-->
          <!--              Bank/ACH Information-->
          <!--            </div>-->
          <!--            <div class="profile__info-block white">-->
          <!--              <div class="profile__info-list">-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">First Name:</div>-->
          <!--                  <div class="profile__info-value">AVROM</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Last Name:</div>-->
          <!--                  <div class="profile__info-value">MULODZHANOV</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Address One:</div>-->
          <!--                  <div class="profile__info-value">110-22 LIBERTY AVE</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Address Two:</div>-->
          <!--                  <div class="profile__info-value">&#45;&#45;&#45;&#45;</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Email Address:</div>-->
          <!--                  <div class="profile__info-value">LIBERTYCHEMISTSINC@GMAIL.COM</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">City:</div>-->
          <!--                  <div class="profile__info-value">RICHMOND HILL</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">State:</div>-->
          <!--                  <div class="profile__info-value">NY</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Zip:</div>-->
          <!--                  <div class="profile__info-value">11419</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Account Type:</div>-->
          <!--                  <div class="profile__info-value">Checking</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Account Number:</div>-->
          <!--                  <div class="profile__info-value">********3711</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Routing Number:</div>-->
          <!--                  <div class="profile__info-value">*****0322</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Name Of Account:</div>-->
          <!--                  <div class="profile__info-value">Liberty Chemists Inc</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">Bank Name:</div>-->
          <!--                  <div class="profile__info-value">Bank of America</div>-->
          <!--                </div>-->
          <!--                <div class="profile__info-item">-->
          <!--                  <div class="profile__info-name w-35">eCheck Type:</div>-->
          <!--                  <div class="profile__info-value">WEB</div>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="col-md-12 col-sm-12">
            <div class="profile__title white">
              Review the following statements
            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12">
                <div class="profile__info-block white">
                  <div class="profile__answers">
                    <div class="profile__answers-item" v-for="(question, index) in userAnswers" :key="index">
                      <p>{{ question.question }}</p>
                      <span>{{ question.answer }}</span>
                      <div class="sub-question" v-if="question.sub_questions.length">
                        <div class="sub-question__item" v-for="(subQuestion, index) in question.sub_questions" :key="index">
                          <p>{{ subQuestion.question }}</p>
                          <span>{{ subQuestion.answer }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--            <div class="profile__info-block">-->
            <!--              <div class="profile__info-list">-->
            <!--                <div class="profile__info-item">-->
            <!--                  <div class="profile__info-name w-20">Signature:</div>-->
            <!--                  <div class="profile__info-value">Date:</div>-->
            <!--                </div>-->
            <!--                <div class="profile__info-item">-->
            <!--                  <div class="profile__info-name w-20">AVROM MULODZHANOV</div>-->
            <!--                  <div class="profile__info-value">17/10/2021</div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <div class="payment-popup"  v-if="togglePaymentMethodPopup">
      <div class="payment-popup__block">
        <div class="payment-popup__close" @click="togglePaymentMethodPopup = false"><inline-svg :src="require(`@/assets/images/svg/close-ic.svg`)"></inline-svg></div>
        <form ref='form' id="payment-form">
          <div id="payment-element">
            <!-- Elements will create form elements here -->
          </div>
          <div class="d-flex justify-center mt-2">
            <button type="submit" class="btn yellow">Send</button>
          </div>
          <div id="error-message">
            <!-- Display error message to your customers here -->
          </div>
        </form>
      </div>
    </div>
    <scroll-up :scroll-duration="1000" :scroll-y="250"></scroll-up>
  </div>
</template>
<script>
import {maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
  components:{
    Header: () => import('../components/common/header'),
    Footer: () => import('../components/common/footer'),
    ScrollUp: () => import('vue-scroll-up'),
  },
  data(){
    return{
      passwordRegexError: false,
      form:{
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      togglePaymentMethodPopup: false,
      checkoutPaymentMethod: false,
      vacation_mode: false
    }
  },
  validations: {
    form: {
      old_password: {
        required
      },
      password: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(40)
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  computed:{
    ...mapGetters({
      profile: 'profile/profile',
      userAnswers: 'profile/userAnswers',
      payoutAccountUrl: 'profile/payoutAccountUrl',
      paymentMethodSecret: 'profile/paymentMethodSecret',
    })
  },
  watch:{
    payoutAccountUrl(e){
      window.open(e.url, "_blank");
    }
  },
  created() {
    if(this.$route.query.connected_payment === null){
      this.checkoutPaymentMethod = true;
    }
    if(!this.profile && !localStorage.getItem('profile')){
        this.getProfile().then(res=>{
          if(!res.status){
            this.$toasted.error(res.data,{
              duration: 3000
            });
          }
        });
    }else if(localStorage.getItem('profile')){
      this.changeProfile(JSON.parse(localStorage.getItem('profile')))
    }
    this.getUserAnswers().then(res=>{
      if(!res.status){
        this.$toasted.error(res.data,{
          duration: 3000
        });
      }
    });
  },
  methods:{
    submitPaymentMethod(){
      this.togglePaymentMethodPopup = true;
      this.changeToggleLoading(true);
      this.connectPaymentMethod().then(res=>{
        if(res.status){
          const stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)
          const options = {
            clientSecret: this.paymentMethodSecret.client_secret,
          };

          // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 2
          const elements = stripe.elements(options);

          // Create and mount the Payment Element
          const paymentElement = elements.create('payment');
          paymentElement.mount('#payment-element');
          this.changeToggleLoading(false)
          this.$refs.form.addEventListener('submit', async event => {
            event.preventDefault();
            const {error} = await stripe.confirmSetup({
              //`Elements` instance that was used to create the Payment Element
              elements,
              confirmParams: {
                return_url:'https://traderx.market/profile?connected_payment',
              }
            });
            if (error) {
              // This point will only be reached if there is an immediate error when
              // confirming the payment. Show error to your customer (for example, payment
              // details incomplete)
              const messageContainer = document.querySelector('#error-message');
              messageContainer.textContent = error.message;
            } else {
              // Your customer will be redirected to your `return_url`. For some payment
              // methods like iDEAL, your customer will be redirected to an intermediate
              // site first to authorize the payment, then redirected to the `return_url`.
            }
          });
        }else{
          this.$toasted.error(JSON.stringify(res.data))
          this.changeToggleLoading(false)
        }
      })
    },
    changeVacationMode(){
      this.updateProfile({on_vacation: Number(!this.profile.on_vacation)}).then(res=>{
        if(res.status){
          this.$toasted.success('vacation mode changed', {
            duration: 3000,
          })
        }
      })
    },
    validatePasswordForm(){
      if(this.form.password.match('^.*(?=.{3,})(?=.*[a-zA-Z])(?=.*[0-9]).*$')){
        this.passwordRegexError = false;
        return true
      }else{
        this.passwordRegexError = true;
        return false
      }
    },
    changePassword(){
      this.$v.$touch();
      if(!this.$v.form.$error && this.validatePasswordForm()){
        this.sendNewPassword(this.form).then(res=>{
          if(res.status){
            this.$toasted.success('password successfully changed',{
              duration: 3000
            })
            this.form = {
              old_password: '',
              password: '',
              password_confirmation: '',
            }
            this.$v.$reset();
          }else{
            Object.keys(res.data.errors).map(item=>{
              this.$toasted.error(res.data.errors[item],{
                duration: 3000
              })
            })
          }
        })
      }
    },
    ...mapActions({
      getProfile: 'profile/getProfile',
      sendNewPassword: 'profile/sendNewPassword',
      getUserAnswers: 'profile/getUserAnswers',
      connectPayoutAccount: 'profile/connectPayoutAccount',
      connectPaymentMethod: 'profile/connectPaymentMethod',
      updateProfile: 'profile/updateProfile',
    }),
    ...mapMutations({
      changeToggleLoading:'general/changeToggleLoading',
      changeProfile:'profile/changeProfile'
    })
  },
}
</script>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.profile{
  overflow: hidden;
  &__title{
    font-size: 24px;
    font-family: 'Mont', Arial;
    font-weight: 900;
    color: $grey;
    margin-bottom: 40px;
    text-transform: uppercase;
    &.white{
      color: $white;
    }
  }
  &__image{
    width: 100%;
    max-width: 370px;
    height: 370px;
    img{
      height: 100%;
      width: 100%;
      object-fit: contain;
      border-radius: 27px;
    }
  }
  &__info-item{
    display: flex;
    width: 100%;
    margin-bottom: 12px;
    &:last-child{
      margin-bottom: 0;
    }
  }
  &__info-name,
  &__info-value{
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 14px;
    color: $grey;
    &.w-20{
      width: 20%;
    }
    &.w-25{
      width: 25%;
    }
    &.w-35{
      width: 35%;
    }
    &.w-40{
      width: 40%;
    }
    &.w-50{
      width: 50%;
    }
  }
  &__info-name{
    opacity: 0.6;
  }
  &__info-block{
    border-radius: 27px;
    width: 100%;
    background-color: $white-grey;
    padding: 20px 50px;
    min-height: 160px;
    &.white{
      background-color: $white;
      box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    }
  }
  &__answers{
    display: flex;
    flex-direction: column;
  }
  &__answers-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
    flex-wrap: wrap;
    p{
      font-family: 'Inter', Arial;
      font-weight: 300;
      font-size: 16px;
      color: $grey;
      padding-right: 50px;
      width: 80%;
    }
    span{
      font-family: 'Montserrat';
      font-weight: 600;
      font-size: 16px;
      color: $grey;
      width: 20%;
    }
    .sub-question{
      width: 100%;
      &__item{
        display: flex;
        margin: 25px 0 25px 25px;
        &:last-child{
          margin-bottom: 0;
        }
      }
    }
  }
  &__section{
    position: relative;
    padding: 40px 0 60px;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 162px;
      width: 100vw;
      background-color: $grey;
      z-index: -1;
    }
    &.no-bg{
      &:before{
        background-color: transparent;
      }
    }
  }
  &__without-photo{
    width: 100%;
    max-width: 370px;
    height: 370px;
    background-color: $white-grey;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Inter', Arial;
    font-size: 24px;
    font-weight: 700;
  }
  &__connected-status{
    padding: 15px;
    font-size: 18px;
    border-radius: 30px;
    border: 1px solid $blue;
    margin: 10px;
  }
}
.payment-popup{
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0, 0.5);
  &__block{
    background: #FFFFFF;
    border-radius: 27px;
    position: relative;
    max-width: 85%;
    max-height: 90%;
    padding: 35px;
    min-height: 200px;
    min-width: 200px;
    overflow-y: auto;
    @media screen and (max-width: 992px) {
      max-width: 95%;
      max-height: 95%;
    }
  }
  &__close{
    position: absolute;
    top: 10px;
    right:10px;
    cursor: pointer;
  }
}
form#payment-form{
  width: 100%;
  min-width: 820px;
  @media screen and (max-width: 1220px) {
    min-width: 720px;
  }
  @media screen and (max-width: 992px) {
    min-width: 500px;
  }
  @media screen and (max-width: 550px) {
    min-width: auto;
  }
}
</style>
