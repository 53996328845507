<template>
  <div class="buy-orders">
    <Header/>
    <menuList
        :menu="ordersMenu"
        :toggleVacationMode="false"
        class="mb-2"
    />
    <div class="container mb-2">
      <div class="row">
        <div class="title col-sm-12">
          <div class="row">
            <span>Search Sold Orders</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-2">
      <div class="row">
        <Search/>
      </div>
    </div>
    <div class="buy-orders__list" v-if="soldOrders && soldOrders.data.length">
      <div class="container">
        <div class="row">
          <div class="title white col-sm-12 col-md-8 mb-2">
            <div class="row">
              <span>Sold Orders</span>
            </div>
          </div>
          <div class="search-sort col-sm-12 col-md-4">
            <p :class="!activeSortOrders ? 'active' : null" @click="changeSortOrders('asc')">new orders</p>
            <p  :class="activeSortOrders ? 'active' : null" @click="changeSortOrders('desc')">old orders</p>
          </div>
          <Order
              v-for="item in soldOrders.data"
              :key="item.id"
              :item="item"
              @getDetailOrder="getDetailOrder"
              class="col-sm-12 mb-2"
          />
        </div>
      </div>
    </div>
    <div class="container mb-5" v-else>
      <div class="row">
        <h2 class="buy-orders__empty">Your sold order list is empty</h2>
      </div>
    </div>
    <pagination/>
    <Footer/>
  </div>
</template>
<script>
import {mapGetters, mapActions, mapMutations} from "vuex";
export default {
  components:{
    Header: () => import('../../components/common/header'),
    Footer: () => import('../../components/common/footer'),
    menuList: () => import('../../components/common/menu-list'),
    Search: () => import('../../components/orders/sellSearch'),
    Order: () => import('../../components/orders/sellOrder'),
    pagination: () => import('../../components/common/pagination'),
  },
  data() {
    return {
      activeSortOrders:0,
    }
  },
  computed:{
    ...mapGetters({
      ordersMenu:'general/ordersMenu',
      soldOrders: 'orders/soldOrders',
      sellOrder: 'orders/sellOrder',
      sellOrderParams:'orders/sellOrderParams'
    })
  },
  watch:{
    sellOrder(e){
      this.soldOrders.data.map(item => {
        if (item.id === e.id) {
          item = e;
          this.$forceUpdate();
        }
      })
    }
  },
  created() {
    if(this.$route.params.id){
      this.getSoldOrders({order_number: this.$route.params.id})
    }else{
      this.getOrdersWithParams()
    }
  },
  methods:{
    getDetailOrder(id){
      this.getSoldOrderDetail(id)
    },
    getOrdersWithParams(){
      const payload = [];
      Object.keys(this.sellOrderParams).map(item=>{
        if(this.sellOrderParams[item].length){
          payload[item] = this.sellOrderParams[item];
        }
      })
      this.getSoldOrders(payload)
    },
    changeSortOrders(type){
      this.changeSellOrderParams({'order': type})
      if(type === 'asc'){
        this.activeSortOrders = 0;
      }else{
        this.activeSortOrders = 1;
      }
      this.getOrdersWithParams()
    },
    ...mapActions({
      getSoldOrders: 'orders/getSoldOrders',
      getSoldOrderDetail: 'orders/getSoldOrderDetail'
    }),
    ...mapMutations({
      changeSellOrderParams: 'orders/changeSellOrderParams'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
.buy-orders{
  &__list{
    position: relative;
    padding: 45px 0;
    margin-bottom: 60px;
    @media screen and (max-width: 992px){
      padding: 35px 15px;
    }
    &:before{
      content: '';
      position: absolute;
      height: 400px;
      width: 100%;
      left: 0;
      top: 0;
      background-color: $grey;
      z-index: -1;
    }
  }
  &__empty{
    width: 100%;
    border-radius: 27px;
    background-color: $white-grey;
    padding: 15px;
    display: flex;
    justify-content: center;
    font-family: 'Inter', Arial;
    font-size: 18px;
    font-weight: 700;
    border:1px solid $grey;
  }
}
</style>
