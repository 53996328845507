<template>
  <div class="reset-password">
    <div class="reset-password__block">
      <div class="reset-password__title">
        Reset password
      </div>
      <label class="input-block col-md-12">
        <input type="email" v-model="form.email"><span/>
        <span class="input-block__name" :class="form.email.length ?'active' : null" >E-mail</span>
        <small class="input-block__error" v-if="$v.form.email.$dirty && $v.form.email.$error">email must be valid</small>
      </label>
      <label class="input-block col-md-12">
        <input type="password" v-model="form.password"><span/>
        <span class="input-block__name" :class="form.password.length ?'active' : null" >Password</span>
        <small class="input-block__error" v-if="$v.form.password.$dirty && $v.form.password.$error">password be valid</small>
      </label>
      <label class="input-block col-md-12">
        <input type="password" v-model="form.password_confirmation"><span/>
        <span class="input-block__name" :class="form.password_confirmation.length ?'active' : null" >Confirm password</span>
        <small class="input-block__error" v-if="$v.form.password_confirmation.$dirty && $v.form.password_confirmation.$error">Confirm password be valid</small>
      </label>
      <button class="btn grey mt-5" @click="resetPassword()">Auth</button>
      <router-link to="/" class="input-block__link">Back to site</router-link>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations} from "vuex";
import {email, required, sameAs} from "vuelidate/lib/validators";

export default {
  data(){
    return{
      form:{
        email:'',
        token:'',
        password:'',
        password_confirmation:'',
      },
    }
  },
  validations: {
    form: {
      email: {
        email,
        required
      },
      password: {
        required
      },
      password_confirmation: {
        required,
        sameAsPassword: sameAs('password')
      },
    }
  },
  created() {
    this.form.token = this.$route.params.id
  },
  methods:{
    resetPassword(){

      this.$v.$touch();
      if(!this.$v.form.$error){
        this.sendResetPassword(this.form).then(res=>{
          if(res.status){
            this.toggleAlertPopup({
              status: true,
              content: {title: 'Success!', text: 'you have successfully changed your password', type: "success"
              }
            });
            this.$router.push('/');
          }else{
            this.toggleAlertPopup({
              status: true,
              content: {title:'Error', text: res.data.message, type:"Error!"}
            });
          }
        })
      }
    },
    ...mapActions({
      sendResetPassword:'auth/sendResetPassword'
    }),
    ...mapMutations({
      toggleAlertPopup: 'general/toggleAlertPopup'
    })
  }
}
</script>

<style lang="scss" scoped>
@import "src/assets/styles/styles";
.reset-password{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__block{
    padding: 25px;
    border-radius: 5px;
    box-shadow: 0 0 0 5px 1px rgba(0,0,0,0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  &__title{
    font-family: 'Mont';
    font-weight: 300;
    font-size: 56px;
  }
  &__link{
    margin-top: 10px;
    color: $blue;
  }
}
</style>
