<template>
  <div class="cart">
    <Header/>
    <div class="container mt-4 mb-3">
      <div class="row">
        <div class="title col-sm-12">
          <div class="row">
            <span>cart</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container mb-2">
      <div class="row">
        <div class="cart__top mb-2">
          <div class="cart__top-left">
            <h4>Seller: Premium Pharmacy</h4>
            <p>100% of Ground shipping cost is cover by the seller.
              50% of Express shipping cost is covered by the seller.</p>
          </div>
          <div class="cart__top-center">
            <p>Please allow 24-48 hours for sellers to process your order</p>
          </div>
          <div class="cart__top-right">
            <button class="btn blue">Supplier’s Other Listings</button>
          </div>
        </div>
      </div>
    </div>

    <div class="cart__list" v-if="cartProducts && cartProducts.data.length">

      <div class="container">
        <div class="row">
          <div class="title white col-sm-12 col-md-8 mb-2">
            <div class="row">
              <span>Review your order</span>
            </div>
          </div>
          <div class="search-sort col-sm-12 col-md-4">
            <p class="active">new orders</p>
            <p>old orders</p>
          </div>
          <cartItem
              v-for="item in cartProducts.data"
              :key="item.id"
              :item="item"
              class="col-sm-12 mb-2"
          />
          <div class="cart__ordering col-sm-12">
            <div class="cart__ordering-top">
              <div class="cart__ordering-shipping">
                <h4>Shipping</h4>
                <img src="@/assets/images/fedex-logo.png" alt="fedex-logo">

                <div class="cart__ordering-choice" v-if="shippingMethods">
                  <div class="radio-block" v-for="item in shippingMethods.data" :key="item.name">
                    <label @click="changeShippingPrice(item)">
                      <input type="radio" name="340BStatus" class="radio" :value="item.name" v-model="shipping_type">
                      <span>{{ item.title }} (${{ item.price }})</span>
                    </label>
                  </div>
                </div>


              </div>
              <div class="cart__ordering-text">
                **When purchasing refrigerated items, buyer MUSon for "Overnight" shipping at Checkout. Buyer beware you will be charged overnight fees even if it was not selected at checkout. Orders placed on Friday or on a Holiday will be shipped the next business day.**
              </div>
              <ul class="cart__ordering-price" v-if="cartProducts">
                  <li>
                    <p>No of Products</p>
                    <span>{{cartProducts.data.length}}</span>
                  </li>
                  <li>
                    <p>Item Total</p>
                    <span v-html="showAllPrice(cartProducts.data)"></span>
                  </li>
                  <li>
                    <p>Shipping Charges</p>
                    <span>${{shippingPrice}}</span>
                  </li>
                  <li class="total">
                    <p>Supplier Total</p>
                    <span v-html="showResultPrice(cartProducts.data, shippingPrice)">$4058</span>
                  </li>
              </ul>
            </div>
            <div class="cart__ordering-bottom">
              <div class="cart__ordering-info">
                <b>Bank Information On File</b>
                <p>
                  <inline-svg :src="require(`@/assets/images/svg/ok-y-ic.svg`)"/>
                  Account Ending
                </p>
              </div>
              <div class="cart__ordering-btn">
                <button class="btn blue" @click="readyToBuy">
                  Process Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cart__empty" v-else>
      <div class="container">
        <div class="row">
          <p>Your cart list is empty</p>
        </div>
      </div>
    </div>
    <div class="note">
      <div class="container">
        <div class="row">
          <div class="title col-sm-12">
            <div class="row">
              <span>PLEASE NOTE:</span>
            </div>
          </div>
          <p class="mb-4">For orders placed with wholesalers every attempt is made to process all orders the same business day if submitted before 5 p.m. EST. If this is your first order, please allow one to two business days for our wholesale partners to process your account information.</p>

          <div class="title col-sm-12">
            <div class="row">
              PLEASE NOTE:
            </div>
          </div>
          <ol>
            <li>Package Condition Information</li>
            <li>Important: Items must be in original packaging with visible NDC, lot #, expiration date.</li>
            <li>It is the sellers responsibility to accurately describe any damage or markings on the container.</li>
            <li>Seller attests the item has been stored and handled under the manufacturers temperature and storage requirements, was not purchased using a government discount program (i.e 340b) or preferred pricing, is not restricted to a limited distribution network, and was acquired from a manufacturer or wholesaler in compliance with the Drug Supply Chain Security Act (DSCSA) and posted item is not classified as a controlled substance.</li>
            <li>All sales are final. No Returns will be accepted back from buyers.</li>
            <li>Buyer and seller are advised to document Serial Number for each Bottle if provided on the back of the Paper Purchase Order for Record keeping.</li>
          </ol>
        </div>
      </div>
    </div>
    <Footer/>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
export default {
  components:{
    Header: () => import('@/components/common/header'),
    Footer: () => import('@/components/common/footer'),
    cartItem: () => import('@/components/cart/cartItem'),
  },
  data() {
    return {
      shipping_type: 'ground',
      shippingPrice: '15.75',
    }
  },
  computed:{
    ...mapGetters({
      ordersMenu:'general/ordersMenu',
      cartProducts:'product/cartProducts',
      shippingMethods:'orders/shippingMethods',
      toggleLoading:'general/toggleLoading',
    })
  },
  watch:{
    shippingMethods(e){
      e.data.map(shippingMethodItem => {
        if(shippingMethodItem.name === "ground"){
          this.shippingPrice = shippingMethodItem.price
        }
      })
    }
  },
  created() {
    // this.changeToggleLoading(true)
    this.getCartProducts();
    this.getShippingMethods();
  },
  methods:{
    readyToBuy(){
      this.changeToggleLoading(true)
      this.buyOrdersInCard(this.shipping_type).then(res => {
        if(res.status){
          this.getCartProducts();
          this.$toasted.success('products have been successfully purchased', {
            duration: 3000
          })
        }else{
          this.$toasted.error(JSON.stringify(res.data), {
            duration: 3000
          })
        }
        this.changeToggleLoading(false)
      });
    },
    showAllPrice(list){
      let price = 0;
      list.map(item => {
        price += item.price * item.quantity
      })
      return '$' + price
    },
    changeShippingPrice(item){
      this.shippingPrice = item.price
      this.$forceUpdate();
    },
    showResultPrice(list, shippingPrice){
      let price = 0;
      list.map(item => {
        price += item.price * item.quantity
      })
      return '$ '+ (Number(price) + Number(shippingPrice)).toFixed(2);
    },
    ...mapActions({
      getCartProducts:'product/getCartProducts',
      buyOrdersInCard:'orders/buyOrdersInCard',
      getShippingMethods: 'orders/getShippingMethods'
    }),
    ...mapMutations({
      changeToggleLoading: 'general/changeToggleLoading'
    })
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/styles/styles";
.cart{
  &__list{
    position: relative;
    padding: 45px 0;
    margin-bottom: 60px;
    @media screen and (max-width: 992px){
      padding: 35px 15px;
    }
    &:before{
      content: '';
      position: absolute;
      height: 400px;
      width: 100%;
      left: 0;
      top: 0;
      background-color: $grey;
      z-index: -1;
    }
  }
  &__top{
    display: flex;
    align-items: center;
    @media screen and (max-width: 992px){
      flex-wrap: wrap;
    }
  }
  &__top-left{
    padding: 24px;
    border-radius: 27px;
    background-color: $white-grey;
    color: $grey;
    max-width: 39%;
    margin-right: 1%;
    @media screen and (max-width: 992px){
      margin-right: 0;
      max-width: 100%;
    }
    h4{
      font-size: 18px;
      font-family: 'Inter', Arial;
      font-weight: 700;
      margin-bottom: 15px;
    }
    p{
      font-family: 'Inter', Arial;
      font-weight: 400;
      font-size: 16px;
      line-height: 26px;
    }
  }
  &__top-center{
    font-family: 'Inter', Arial;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    padding: 24px 50px;
  }
  &__ordering{
    background: $white;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    border-radius: 27px;
    padding: 0;
  }
  &__ordering-top{
    padding: 15px;
    display: flex;
    h4{
      font-family: 'Inter', Arial;
      font-weight: 700;
      color: $grey;
      font-size: 18px;
    }
  }
  &__ordering-bottom{
    display: flex;
    flex-direction: column;
  }
  &__ordering-info{
    width: 100%;
    padding: 15px 30px;
    background-color: $grey;
    margin: 15px 0;
    color: $white;
    display: flex;
    b{
      font-family: 'Mont', Arial;
      font-size: 20px;
      font-weight: 900;
      margin-right: 15%;
    }
    p{
      font-family: 'Inter', Arial;
      font-weight: 400;
      font-size: 18px;
      margin-bottom: 0;
    }
  }
  &__ordering-btn{
    margin: 25px;
    display: flex;
    justify-content: flex-end;
  }
  &__ordering-shipping{
    max-width: 35%;
    min-width: 30%;
    display: flex;
    flex-wrap: wrap;
    border-radius: 27px;
    background-color: $white-grey;
    padding: 20px 15px 20px 40px;
    justify-content: space-between;
    @media screen and (max-width: 920px){
      width: 100%;
    }
    img{
      max-width: 105px;
      max-height: 30px;
    }
  }
  &__ordering-text{
    max-width: 40%;
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: $grey;
    padding: 0 2%;
    @media screen and (max-width: 920px){
      width: 100%;
    }
  }
  &__ordering-choice{
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-top: 15px;
    .radio-block{
      min-height: auto;
      margin-bottom: 0;
      label{
        input{
          background-color: $white;
          height: 20px;
          width: 20px;
          margin-top: 0;
        }
      }
      span{
        font-family: 'Inter', Arial;
        font-weight: 400;
        font-size: 16px;;
      }
    }
  }
  &__ordering-price{
    background: #FFFFFF;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    border-radius: 27px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    li{
      padding: 5px 30px;
      display: flex;
      justify-content: space-between;
      font-family: 'Inter', Arial;
      font-size: 16px;
      font-weight: 400;
      p{
        min-width: 200px;
        margin-bottom: 0;
      }
      &.total{
        background-color: $white-grey;
        border-radius: 0 0 27px 27px;
        font-weight: 700;
        padding: 20px 30px;
        span{white-space: nowrap}
      }
    }
  }
  &__empty{
    p{
      text-align: center;
      padding: 10px;
      margin: 50px 5px;
      border-radius: 15px;
      background-color: $white;
      box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.1);
    }
  }
}
.note{
  margin-bottom: 100px;
}
</style>
