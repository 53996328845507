<template>
  <div class="verify-email">
    <div v-if="confirmStatus === 1" class="verify-email__title">You confirm email!</div>
    <div v-if="confirmStatus === 2" class="verify-email__title">This link is not active!</div>
    <router-link to="/" class="verify-email__link">Back to site</router-link>
  </div>
</template>
<style lang="scss" scoped>
@import "src/assets/styles/styles";
.verify-email{
  position: relative;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__title{
    font-family: 'Mont';
    font-weight: 300;
    font-size: 56px;
  }
}
</style>
<script>
import {mapActions} from "vuex";

export default {
  data(){
    return{
      confirmStatus:0,
    }
  },
  created() {
    this.sendVerifyEmail({token:this.$route.params.id}).then(res=>{
      if(res.status){
        this.confirmStatus = 1;
      } else{
        this.confirmStatus = 2;
      }
    })
  },
  methods:{
    ...mapActions({
      sendVerifyEmail:'auth/sendVerifyEmail'
    })
  }
}
</script>
