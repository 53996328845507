import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import axios from "axios";
import router from './router';
import store from './store';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import InlineSvg from 'vue-inline-svg';
import VueWow from 'vue-wow';
import Vuelidate from 'vuelidate';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueTheMask from 'vue-the-mask';
import Toasted from 'vue-toasted';
import i18n from './i18n'
import moment from 'vue-moment'

Vue.config.productionTip = false
Vue.component('v-select', vSelect)
Vue.component('inline-svg', InlineSvg);
Vue.use(Vuex);
Vue.use(VueWow)
Vue.use(Vuelidate)
Vue.use(VueReCaptcha, { siteKey: '6LcKxCUfAAAAANFwSVs4djLjewJwtSgA3jknimVl' })
Vue.use(VueTheMask)
Vue.use(Toasted)
Vue.use(moment)

Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API;

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
