<template>
  <div>
    <Header/>
    <menuList
        :menu="productMenu"
        :toggleVacationMode="true"
        class="mb-2"
    />
    <div class="my-posting">
        <div class="container mb-3">
          <div class="row">
            <div class="title col-md-6 col-sm-12">
              <div class="row">
                <span>My Posting</span>
              </div>
            </div>
            <div class="my-posting__add-product col-md-6 col-sm-12" @click="$router.push('/products/post-item')">
               <span>Add New Product</span>
            </div>
          </div>
      </div>
      <div class="my-posting__list" v-if="profileProductList && profileProductList.data.length">
        <div class="container">
          <div class="row">
            <div class="my-posting__filter mb-5">
              <div class="my-posting__filter-left">
<!--                <label class="checkbox-block col-md-12">-->
<!--                  <input-->
<!--                      type="checkbox"-->
<!--                      class="checkbox"-->
<!--                  >-->
<!--                  <span class="white">Select All {{profileProductList.length}} Products</span>-->
<!--                </label>-->
              </div>
              <div class="my-posting__filter-right">
                <label class="input-block white col-md-12">
                  <input type="text" name="search" v-model="form.search" placeholder=""><span/>
                  <span class="input-block__name" :class="form.search.length ?'active' : null">Search Product</span>
                </label>
                <button class="my-posting__filter-btn" @click="searchProduct()">OK</button>
              </div>
            </div>
            <div class="my-posting__item" v-for="(item, index) in profileProductList.data" :key="index">
              <div class="my-posting__item-lef col-md-4 col-sm-12">
<!--                <label class="checkbox-block col-md-12">-->
<!--                  <input-->
<!--                      type="checkbox"-->
<!--                      class="checkbox"-->
<!--                  >-->
<!--                </label>-->
                <div class="my-posting__item-title">
                  {{ item.product_name }}
                </div>
                <div class="my-posting__item-ndc">
                  NDC {{ item.ndc }}
                </div>
              </div>
              <div class="my-posting__item-center col-md-5 col-sm-12">
                <div class="dashboard-search__item-info">
                  <div class="dashboard-search__item-name">Strength</div>
                  <div class="dashboard-search__item-value">{{item.strength}}</div>
                </div>
                <div class="dashboard-search__item-info">
                  <div class="dashboard-search__item-name">Dosage Form</div>
                  <div class="dashboard-search__item-value">{{item.dosage_form}}</div>
                </div>
                <div class="dashboard-search__item-info">
                  <div class="dashboard-search__item-name">Lot</div>
                  <div class="dashboard-search__item-value">{{item.lot}}</div>
                </div>
                <div class="dashboard-search__item-info">
                  <div class="dashboard-search__item-name">mfr</div>
                  <div class="dashboard-search__item-value">{{item.manufacturer_name}}</div>
                </div>
                <div class="dashboard-search__item-info">
                  <div class="dashboard-search__item-name">Pack quantity</div>
                  <div class="dashboard-search__item-value">{{item.pack_quantity}}</div>
                </div>
                <div class="dashboard-search__item-info" v-if="item.partial_quantity">
                  <div class="dashboard-search__item-name">Pack size</div>
                  <div class="dashboard-search__item-value">{{item.partial_quantity}}</div>
                </div>
                <div class="dashboard-search__item-info">
                  <div class="dashboard-search__item-name">exp date</div>
                  <div class="dashboard-search__item-value">{{item.expiration_date}}</div>
                </div>
                <div class="dashboard-search__item-info" v-if="item.package_condition_id">
                  <div class="dashboard-search__item-name">package condition</div>
                  <div class="dashboard-search__item-value">{{item.package_condition_id.title}}</div>
                </div>
              </div>
              <div class="my-posting__item-right col-md-3 col-sm-12">
                <div class="my-posting__item-top">
                  <div class="dashboard-search__item-price">
                    ${{ item.price }}
                  </div>
                </div>
                <div class="my-posting__item-bottom">
                  <div class="my-posting__item-btn" @click="changeProfileProduct(item)">
                    <inline-svg :src="require('@/assets/images/svg/edit-ic.svg')"/>
                    Edit
                  </div>
                  <div class="my-posting__item-btn" @click="deleteProfileProduct(item)">
                    <inline-svg :src="require('@/assets/images/svg/remove-ic.svg')"/>
                    Delete
                  </div>
                </div>
              </div>
            </div>
            <div class="my-posting__btn">
              <button class="btn blue" @click="$router.push('/products/post-item')">Add New Product</button>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-2 mb-2" v-else>
        <div class="row">
          <div class="my-posting__empty">
            Your product list empty
          </div>
        </div>
      </div>
      <Pagination
        v-if="profileProductList"
        :meta="profileProductList.meta"
        @changeCurrentPage="changeCurrentPage"
      />
    </div>
    <Footer/>
  </div>
</template>
<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import PaginationMixin from '../../mixins/pagination'
  export default {
    components:{
      Header: () => import('../../components/common/header'),
      Footer: () => import('../../components/common/footer'),
      menuList: () => import('../../components/common/menu-list'),
      Pagination: () => import('../../components/common/pagination')
    },
    mixins:[PaginationMixin],
    data(){
      return{
        form:{
          search:''
        },
        list:{
          strength:'3 MG / 0.5 MG',
          dosage_form:'Suspension / Drops',
          lot:'10hut',
          mfr:'Eyevance Pharmaceuticals, LLC',
          pack_quantity:'Full	1',
          pack_size:'100',
          exp_date:'04-2023',
          package_condition:'Sealed Original Containe',
        },
      }
    },
    computed:{
      ...mapGetters({
        productMenu: 'general/productMenu',
        profileProductList: 'profile/profileProductList',
      })
    },
    created() {
      window.scrollTo(0, 0);
      this.getProfileProductList({page: this.page, per_page: this.per_page});
    },
    methods:{
      searchProduct(){
        this.getProfileProductList({page: this.page, per_page: this.per_page, search_param: this.form.search});
      },
      changeProfileProduct(item){
        this.editProfileProduct(item);
        this.$router.push('/products/post-item')
      },
      deleteProfileProduct(item){
        this.removeProfileProduct(item).then(res=>{
          if(res.status){
            this.$toasted.success('Product success remove', {
              duration: 3000
            })
            this.getProfileProductList({page: this.page, per_page: this.per_page});
          }
        });
      },
      changeCurrentPage(e){
        this.getProfileProductList({page: e, per_page: this.per_page});
      },
      ...mapMutations({
        editProfileProduct: 'profile/editProfileProduct'
      }),
      ...mapActions({
        getProfileProductList: 'profile/getProfileProductList',
        removeProfileProduct: 'profile/removeProfileProduct',
      })
    }
  }
</script>
<style lang="scss" scoped>
@import "../../assets/styles/styles";
.my-posting{
  &__add-product{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span{
      font-family: 'Inter', Arial;
      font-weight: 400;
      font-size: 16px;
      color: $grey;
      opacity: 0.5;
      cursor: pointer;
      position: relative;
      padding-left: 45px;
      line-height: 2;
      transition: 0.3s all;
      &:hover{
        opacity: 0.8;
      }
      &:before{
        content: '+';
        position: absolute;
        opacity: 0.5;
        left: 0;
        color: $white;
        background-color: $grey;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        font-size: 28px;
        line-height: 1.2;
      }
    }
  }
  &__list{
    padding: 20px 0 40px;
    position: relative;
    &:before{
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 250px;
      width: 100%;
      background-color: $grey;
      z-index: -1;
    }
  }
  &__filter{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__filter-right{
    display: flex;
    align-items: center;
    .input-block{
      margin-right: 30px;
    }
    input{
      min-width: 250px;
    }
  }
  &__filter-btn{
    height: 57px;
    width: 57px;
    min-height: 57px;
    min-width: 57px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $white;
    border-radius: 15px;
    background-color: $blue;
    font-size: 24px;
    font-family: 'Inter', Arial;
    font-weight: 400;
    position: relative;
    transition:0.3s all;
    &:hover{
      opacity: 0.8;
      box-shadow: 0 0 5px 1px rgba(0,0,0,0.1);
    }
  }
  &__item{
    background-color: #FFFFFF;
    box-shadow: 0px 0px 11px rgba(59, 64, 240, 0.1);
    border-radius: 27px;
    padding: 20px 35px;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 920px){
      flex-direction: column;
    }
    .checkbox-block{
      padding: 0;
    }
  }
  &__item-title{
    color: $grey;
    font-family: 'Inter', Arial;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 30px;
  }
  &__item-right{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__item-ndc{
    padding: 14px 25px;
    width: 100%;
    border-radius: 13px;
    background-color: $white-grey;
    font-family: 'Inter', Arial;
    font-size: 16px;
    font-weight: 400;
  }
  &__item-info{
    display: flex;
    flex-direction: column;
  }
  &__item-name{
    width: 40%;
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 12px;
    text-transform: uppercase;
    color: $grey;
    opacity: 0.5;
    margin-bottom: 5px;
  }
  &__item-value{
    width: 60%;
    font-family: 'Inter', Arial;
    font-weight: 400;
    font-size: 16px;
    margin-bottom: 5px;
  }
  &__item-top{
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__item-price{
    font-size: 26px;
    font-family: 'Inter', Arial;
    font-weight: 900;
    text-align: center;
  }
  &__item-bottom{
    display: flex;
    align-items: center;
  }
  &__item-btn{
    display: flex;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    svg{
      margin-right: 3px;
    }
  }
  &__btn{
    display: flex;
    justify-content: center;
    margin-top: 75px;
  }
  &__empty{
    width: 100%;
    border-radius: 27px;
    background-color: $white-grey;
    padding: 15px;
    display: flex;
    justify-content: center;
    font-family: 'Inter', Arial;
    font-size: 18px;
    font-weight: 700;
    border:1px solid $grey;
  }
}
</style>
